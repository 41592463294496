import { createSlice } from "@reduxjs/toolkit";
import { Status, UserModel } from "../../interfaces/interfaces";
import userService from "../../services/UserService";

export interface UserState {
  user: UserModel | null;
  status: Status;
}

const initialState: UserState = {
  user: null,
  status: Status.IDLE,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setUser, setStatus } = userSlice.actions;
export default userSlice.reducer;

export function fetchUserById(
  userId: string,
  role: string,
  permissions: { _id: string; name: string }[]
) {
  return async function fetchUserByIdThunk(dispatch: any) {
    dispatch(setStatus(Status.LOADING));
    try {
      const { data } = await userService.getUserByUserId(userId);
      const user = {
        ...data,
        role,
        permissions: permissions.map((item) => item.name),
      };
      dispatch(setUser(user));
      dispatch(setStatus(Status.IDLE));
    } catch (err) {
      dispatch(setStatus(Status.ERROR));
    }
  };
}
