import { Roles } from "./role.interface";

export enum Status {
  LOADING = "loading",
  ERROR = "error",
  IDLE = "idle",
}

// export enum UserRoles {
//   VENDOR = "vendor",
//   CLIENT = "client",
//   SUPER_ADMIN = "super-admin",
// }

export interface UserModel {
  _id: string;
  name: string;
  email: string;
  roleID: string;
  mobile: string;
  role: Roles;
  depots: { _id: string; name: string }[];
  division?: any;
  permissions: string[];
}

export interface IAddBus {
  registerationNumber: string;
  busType: string;
  manufacturer: string;
  depot: string;
  registerationDate: string;
  fitnessCertificateValidityDate: string;
  aluminiumToMSConversionDate: string;
  progressiveKilometer: ProgressiveKilometer;
  engineType: string;
  engineNo: string;
  chasisNo: string;
  fipNo: string;
  starterNumber: string;
  alternateNumber: string;
  gearBoxNumber: string;
  rearHousingNumber: string;
  tyreDetails: TyreDetails;
  powerSteering: string;
  bodyConditionR: string;
  seatConditionR: string;
  engineConditionR: string;
  fuelpipelineconditionsR: string;
  brakeSystemConditionR: string;
  headLightR: string;
  tailLightR: string;
  kiranLightR: string;
  roofLightR: string;
  passengerSaloonLightR: string;
  dashBoardConditionR: string;
  HSD: string;
  KMPL: Kmpl;
  AnyOtherR: string;
  division: any;
}

export interface ProgressiveKilometer {
  pkDate: string;
  pkKilometer: string;
}

export interface TyreDetails {
  NF: Nf;
  OF: Of;
  NRI: Nri;
  NRO: Nro;
  ORI: Ori;
  ORO: Oro;
  SPARE: Spare;
}

export interface Nf {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Of {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Nri {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Nro {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Ori {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Oro {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Spare {
  size: string;
  type: string;
  condition: string;
  number: string;
}

export interface Kmpl {
  firstYear: string;
  firstMonth: string;
  firstKMPL: string;
  secondYear: string;
  secondMonth: string;
  secondKMPL: string;
}

export interface IAddBusFrom {
  registerationNumber: string;
  busType: string;
  manufacturer: string;
  depot: string;
  division: any;
  registerationDate: string;
  fitnessCertificateValidityDate: string;
  aluminiumToMSConversionDate: string;
  pkDate: string;
  pkKilometer: string;
  engineType: string;
  engineNo: string;
  chasisNo: string;
  fipNo: string;
  starterNumber: string;
  alternateNumber: string;
  gearBoxNumber: string;
  rearHousingNumber: string;
  "NF-size": string;
  "NF-type": string;
  "NF-old-new": string;
  "NF-number": string;
  "OF-size": string;
  "OF-type": string;
  "OF-old-new": string;
  "OF-number": string;
  "NRI-size": string;
  "NRI-type": string;
  "NRI-old-new": string;
  "NRI-number": string;
  "NRO-size": string;
  "NRO-type": string;
  "NRO-old-new": string;
  "NRO-number": string;
  "ORI-size": string;
  "ORI-type": string;
  "ORI-old-new": string;
  "ORI-number": string;
  "ORO-size": string;
  "ORO-type": string;
  "ORO-old-new": string;
  "ORO-number": string;
  "SPARE-size": string;
  "SPARE-type": string;
  "SPARE-old-new": string;
  "SPARE-number": string;
  powerSteering: string;
  HSD: string;
  bodyConditionR: string;
  seatConditionR: string;
  engineConditionR: string;
  fuelpipelineconditionsR: string;
  brakeSystemConditionR: string;
  headLightR: string;
  tailLightR: string;
  kiranLightR: string;
  roofLightR: string;
  passengerSaloonLightR: string;
  dashBoardConditionR: string;
  firstYear: string;
  firstMonth: string;
  firstKMPL: string;
  secondYear: string;
  secondMonth: string;
  secondKMPL: string;
  AnyOtherR: string;
}

export interface IBusList {
  data: Data;
}

export interface Data {
  busType: BusType[];
  manufacturer: Manufacturer[];
  engineType: EngineType[];
  tyreCondition: TyreCondition[];
  powerSteering: PowerSteering[];
  hsd: Hsd[];
  depot: DepotName[];
  tyreType: TyreType[];
  tyreSize: TyreSize[];
}

export interface BusType {
  _id: string;
  name: string;
}

export interface Manufacturer {
  _id: string;
  name: string;
}

export interface EngineType {
  _id: string;
  name: string;
}

export interface TyreCondition {
  _id: string;
  name: string;
}

export interface PowerSteering {
  _id: string;
  name: string;
}

export interface Hsd {
  _id: string;
  quantity: string;
}

export interface DepotName {
  _id: string;
  name: string;
}

export interface TyreType {
  _id: string;
  name: string;
}

export interface TyreSize {
  _id: string;
  name: string;
}

export interface ILoginResponse {
  data: {
    token: string;
    message: string;
    userId: string;
    role: string;
    permissions: { _id: string; name: string }[];
    redirectUrl: string;
  };
}
