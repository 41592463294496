import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { doLogout } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  const handleLogOut = () => {
    dispatch(doLogout());
  };

  return (
    <>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <a
            className="nav-item nav-link px-0 me-xl-4"
            href="javascript:void(0)"
          >
            <i className="bx bx-menu bx-sm" />
          </a>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          {/* Search */}
          <div className="navbar-nav align-items-center">
            <div className="nav-item d-flex align-items-center">
              {`${user?.role.toUpperCase()}`}{" "}
            </div>
          </div>
          {/* /Search */}
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {`${user?.name.charAt(0).toUpperCase()}${user?.name.slice(1)}`}
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/change-password");
                    }}
                  >
                    <i className="bx bx-user me-2"></i>
                    <span className="align-middle">Change password</span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0);"
                    onClick={handleLogOut}
                  >
                    <i className="bx bx-power-off me-2"></i>
                    <span className="align-middle">Log Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
