import axios from "axios";
import { IUserList } from "../interfaces/user.interface";

export interface IAddUser {
  name: string;
  mobile: string;
  email: string;
  password: string;
  roleID: string;
  location: string;
  depots?: string | string[];
  division?: string | string[] | null;
}

const userService = {
  getUserByUserId: async (userId: string): Promise<{ data: IUserList }> => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
  },

  getUsers: async (): Promise<{ data: IUserList[] }> => {
    const response = await axios.get(`/users`);
    return response.data;
  },

  addUser: async (data: IAddUser) => {
    return await axios.post("/users", data);
  },

  updateUserByUserId: async (
    userId: string,
    data: Omit<IAddUser, "password" | "email">
  ) => {
    return await axios.put(`/users/${userId}`, data);
  },

  deleteUser: async (userId: string) => {
    return await axios.delete("/users", {
      data: {
        ids: [userId],
      },
    });
  },

  getForgotPasswordLink: async (
    email: string
  ): Promise<{ data: IUserList[] }> => {
    const response = await axios.post(
      `/forgotpassword/generatepasswordresetlink`,
      { email: email }
    );
    return response.data;
  },

  forgotPasswordVerifyLink: async (
    token: string
  ): Promise<{ data: IUserList[] }> => {
    const response = await axios.post(`/forgotpassword/verifylink`, {
      token: token,
    });
    return response.data;
  },

  resetPassword: async (data: {
    token: string;
    newPassword: string;
  }): Promise<{ data: IUserList[] }> => {
    const response = await axios.post(`/forgotpassword/resetpassword`, data);
    return response.data;
  },

  changePassword: async (data: {
    oldPassword: string;
    newPassword: string;
  }): Promise<{ data: IUserList[] }> => {
    const response = await axios.post(`/users/changepassword`, data);
    return response.data;
  },
};

export default userService;
