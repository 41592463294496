import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { LoginSchema } from "../../../schemas/login.schema";
import { ILoginForm } from "../../../services/AuthService";
import { doLogin } from "../../../store/slices/authSlice";
import { notificationController } from "../../../utills/toastNotification";
import { handleAxiosError } from "../../../utills/helper";

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleLogin = async (data: ILoginForm) => {
    dispatch(doLogin(data))
      .unwrap()
      .then(() => {
        notificationController.success("Login Successfully");
        setSubmitDisabled(true);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((err: any) => {
        setSubmitDisabled(false);
        handleAxiosError(err);
      });
  };

  const handlePasswordIcon = () => {
    if (passwordShow) {
      setPasswordShow(false);
    } else {
      setPasswordShow(true);
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  useEffect(() => {
    if (process.env.REACT_APP_SITE_UNDER_MAINTENANCE === "true") {
      navigate("/under-maintenace");
    }
  }, []);

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper login-page authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <a href="javascript:void(0);" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <img
                      src="./assets/img/icons/brands/cng-icon.svg"
                      width="42px"
                    />
                  </span>
                  <span
                    className="app-brand-text demo text-body fw-bolder"
                    style={{ textTransform: "uppercase" }}
                  >
                    CRFM
                  </span>
                </a>
              </div>
              {/* /Logo */}
              <h4 className="mb-2">Welcome to CRFM</h4>
              <p className="mb-4">Please sign-in to your account</p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                  await handleLogin(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        {/* <a href="javascript:void(0);">
                          <small>Forgot Password?</small>
                        </a> */}
                      </div>

                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        autoFocus
                        onChange={handleChange}
                      />
                      {errors["email"] && touched["email"] ? (
                        <div style={{ color: "red", marginTop: "2px" }}>
                          {errors["email"]}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <a
                          href="javascript:void(0);"
                          onClick={handleForgotPassword}
                        >
                          <small>Forgot Password?</small>
                        </a>
                      </div>
                      <div className="input-group input-group-merge">
                        <input
                          type={passwordShow ? "text" : "password"}
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="············"
                          aria-describedby="password"
                          onChange={handleChange}
                        />

                        <span className="input-group-text cursor-pointer">
                          <i
                            className={
                              passwordShow ? "bx bx-show" : "bx bx-hide"
                            }
                            onClick={handlePasswordIcon}
                          />
                        </span>
                      </div>
                      {errors["password"] && touched["password"] ? (
                        <div style={{ color: "red", marginTop: "2px" }}>
                          {errors["password"]}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-me"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-me"
                        >
                          {" "}
                          Remember Me{" "}
                        </label>
                      </div>
                    </div> */}
                    <div className="mb-3">
                      <a href="javascript:void(0);">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                          disabled={submitDisabled}
                        >
                          Login
                        </button>
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <p className="text-center">
                <span>New on our platform?</span>
                <a href="javascript:void(0);">
                  <span>Create an account</span>
                </a>
              </p> */}
            </div>
          </div>
          {/* /Register */}
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
