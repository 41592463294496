export enum SidebarPermission {
  VENDOR_DASHBAORD = "vendor_dashboard",
  CLIENT_DASHBAORD = "client_dashboard",
  MASTERS = "all_masters",
  BUS_MASTER = "bus_master",
  DRIVER_MASTER = "drivers_master",
  ROUTE_MASTER = "route_master",
  USER_MASTER = "user_master",
  ROLE_MASTER = "role_master",
  DIVISION_MASTER = "division_master",
  DEPOT_MASTER = "depot_master",
  PARTS_MASTER = "parts_master",
  MANUFACTURER_MASTER = "manufacturer_master",
  PRE_CHECK = "pre_check",
  WIP_PARTS_AWAITED = "wip_parts_awaited",
  WIP = "wip",
  HAND_OVER = "hand_over",
  UPDATE_INVOICE = "generate_invoice",
  UNDO_INVOICE = "undo_invoice",
  UNDO_PAYMENT = "undo_payment",
  UPDATE_PAYMENT = "unpaid_status",
  MAINTENANCE = "maintenance",
  WIP_COMPLETED = "wip_completed",
  SHOW_REPORTS = "show_reports",
  MAINTENANCE_OBSERVATION_MASTER = "maintenance_observation_master",
  ISSUE_MASTER = "issue_master", //
}

export enum MasterPermission {
  ADD_BUS = "create_bus",
  UPDATE_BUS = "update_bus",
  DELETE_BUS = "delete_bus",
  GET_BUS = "get_bus",
  GET_ALL_BUS = "get_all_bus",
  VERIFY_REMOVED_PARTS = "verify_removed_parts",

  ADD_USER = "add_users",
  UPDATE_USER = "update_users",
  DELETE_USER = "delete_users",
  GET_USER = "get_user",
  GET_ALL_USER = "get_all_users",

  ADD_ROLE = "create_roles",
  UPDATE_ROLE = "update_roles",
  DELETE_ROLE = "delete_roles",
  GET_ROLE = "get_role",
  GET_ALL_ROLE = "get_all_roles",

  ADD_DIVISIONS = "add_division",
  UPDATE_DIVISIONS = "update_division",
  DELETE_DIVISIONS = "delete_division",
  GET_DIVISIONS = "get_division",
  GET_ALL_DIVISIONS = "get_all_division",

  ADD_DRIVERS = "add_drivers",
  UPDATE_DRIVERS = "update_drivers",
  DELETE_DRIVERS = "delete_drivers",
  GET_DRIVERS = "get_drivers",
  GET_ALL_DRIVERS = "get_all_drivers",

  ADD_ROUTE = "add_route",
  UPDATE_ROUTE = "update_route",
  DELETE_ROUTE = "delete_route",
  GET_ROUTE = "get_route",
  GET_ALL_ROUTE = "get_all_route",

  ADD_DEPOTS = "add_depots",
  UPDATE_DEPOTS = "update_depots",
  DELETE_DEPOTS = "delete_depots",
  GET_DEPOTS = "get_depots",
  GET_ALL_DEPOTS = "get_all_depots",

  ADD_PARTS = "add_parts",
  UPDATE_PARTS = "update_parts",
  DELETE_PARTS = "delete_parts",
  GET_PARTS = "get_parts",
  GET_ALL_PARTS = "get_all_parts",

  ADD_PARTS_MANUFACTURER = "add_parts_manufacturer",
  UPDATE_PARTS_MANUFACTURER = "update_parts_manufacturer",
  DELETE_PARTS_MANUFACTURER = "delete_parts_manufacturer",
  GET_PARTS_MANUFACTURER = "get_parts_manufacturer",
  GET_ALL_PARTS_MANUFACTURER = "get_all_parts_manufacturer",

  ADD_MAINTENANCE_FIELD = "add_maintenance_field",
  UPDATE_MAINTENANCE_FIELD = "update_maintenance_field",
  DELETE_MAINTENANCE_FIELD = "delete_maintenance_field",
  GET_MAINTENANCE_FIELD = "show_maintenance_field",

  ADD_ISSUE = "add_issue",
  UPDATE_ISSUE = "update_issue",
  DELETE_ISSUE = "delete_issue",
}

export enum DownloadPermission {
  HAND_OVER_DOWNLOAD = "handover_download",
  BUS_DOWNLOAD = "bus_download",
}

export enum MaintenancePermission {
  GET_MAINTENANCE_DASHBOARD = "get_maintenance_dashboard",
  GET_MAINTENANCE_LOG = "get_maintenance_log",
  DAILY_MAINTENANCE = "daily_maintenance",
  MONTHLY_MAINTENANCE = "monthly_maintenance",
  WEEKLY_MAINTENANCE = "weekly_maintenance",
  ADD_BREAKDOWN_MAINTENANCE = "add_breakdown_maintenance",
  ADD_ACCIDENT_MAINTENANCE = "add_accident_maintenance",
  GET_BREAKDOWN_ACCIDENT_BUS_DATA = "breakdown_accident_bus_listing",
  GET_BREAKDOWN_ACCIDENT_DATA = "get_breakdown_accident_data",
  GET_MAINTENANCE_HISTORY = "get_maintenance_history",
  CLIENT_VIEW_MAINTENANCE_LOG = "client_view_maintenance_log",
}

export enum OperationPermission {
  ADD_OPERATION = "add_operation",
  UPDATE_OPERATION = "update_operation",
  GET_OPERATION = "get_operation",
  GET_OPERATION_WITH_START_KM = "get_operation_with_start_km",
}

export enum ReportsPermission {
  GET_REPORTS = "get_reports",
  ADD_REPORTS = "add_reports",
}

export enum BusStatusRedirection {
  BUS_STATUS_REDIRECTION = "bus_status_redirection",
}
