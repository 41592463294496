import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BreakDownMaintenanceWithDivision, BreakDownMaintenanceWithoutDivision } from "../../schemas/maintenance.schema";
import { useLocation, useNavigate } from "react-router-dom";
import maintenanceService from "../../services/MaintenanceService";
import { IBreakdownMaintenance } from "../../interfaces/maintenance.interface";
import { notificationController } from "../../utills/toastNotification";
import { handleAxiosError } from "../../utills/helper";
import dayjs from "dayjs";
import { useDepotList } from "../../hooks/useDepotList";
import depotService from "../../services/DepotService";
import { useAppSelector } from "../../hooks/reduxHooks";
import { Roles } from "../../interfaces/role.interface";
import divisionService from "../../services/DivisionService";

const AddBreakdownMaintenance: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state) => state.user.user);
  const cssHandling = user?.role !== Roles.DIVISION

  const state = location.state as {
    depotId: string;
    busId: string;
    turbo: string;
  };
  const [checkboxVisible, setCheckboxVisible] = useState<boolean>(false);
  const [showTurboSelected, setShowTurboSelected] = useState<boolean>(false);
  const [divisionList, setDivisionList] = useState<any>()
  const [depotList, setDepotList] = useState<any>(null)
  const [showBusList, setShowBusList] = useState<boolean>(false);
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [busList, setBusList] = useState<
    { registerationNumber: string; _id: string }[]
  >([]);
  const { depots } = useDepotList();

  const handleBack = () => {
    navigate("/accident-breakdown");
  };

  const getBusByDepotLink = async (busId: string) => {
    try {
      if (busId) {
        const { data } = await depotService.getDepotDependentBusList(busId);
        if (data.length > 0) {
          setShowBusList(true);
          setBusList(data);
        } else {
          setBusList([]);
        }
      } else {
        setBusList([]);
      }
    } catch (error) {
      setShowBusList(false);
      handleAxiosError(error);
    }
  };

  const handleAddBreakdownMaintenance = async (data: IBreakdownMaintenance) => {
    try {
      await maintenanceService.addBreakdownMaintenance(data);
      notificationController.success(
        "Breakdown Maintenance Added Successfully"
      );
      if (state?.busId && state?.depotId) {
        window.history.replaceState({}, document.title);
      }
      return true;
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  };

  const getDivisionData = async () => {
    try {
      const { data } = await divisionService.getDivisionList();
      setDivisionList(data);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const getUserDepotLists = async (value: any) => {
    if (value !== null) {
      const { data } = await depotService.getUserDepotList(value)
      setDepotList(data)
    }
  }

  const handleCNGRadioClick = () => {
    if (!checkboxVisible) {
      setCheckboxVisible(true);
    }
    if (state?.turbo) {
      setShowTurboSelected(true);
    }
  };

  useEffect(() => {
    getDivisionData()
  }, [])

  useEffect(() => {
    if (state?.busId && state?.depotId && state?.turbo) {
      getBusByDepotLink(state.depotId);
      setSelectedDepot(state.depotId as any);
      setCheckboxVisible(true);
      setShowTurboSelected(true);
    } else if (depots.length === 1) {
      getBusByDepotLink(depots[0]._id);
      setSelectedDepot(depots[0]._id as any);
    }
  }, [depots]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="fw-bold py-3 mb-4">
        <span className="text-muted fw-light">Breakdown Maintenance </span>
      </h4>
      <div className="row">
        <div className="card">
          {depots && depots.length > 0 && (
            <Formik
              initialValues={{
                depot: state?.depotId
                  ? state.depotId
                  : depots.length === 1
                    ? depots[0]._id
                    : "",
                bus: state?.busId ? state.busId : "",
                breakdownInspection: state?.turbo ? "CNG" : "",
                radiatorWater: false,
                compressurePipe: false,
                turboPipe: false,
                kitHighPressurePipe: false,
                kitLowPressurePipe: false,
                compressure: false,
                turboCharger: state?.turbo ? true : false,
                waterBody: false,
                cngKit: false,
                engine: false,
                other: "",
                time: "",
                date: moment().format("YYYY-MM-DD"),
                depotBroughtTo: "",
                gasLeakageB: false,
                accidentalPedal: false,
                division: null,
              }}
              validationSchema={cssHandling ? BreakDownMaintenanceWithDivision : BreakDownMaintenanceWithoutDivision}
              onSubmit={async (values, { resetForm }) => {
                const { division, ...requiredValues } = values
                const payload = {
                  ...requiredValues,
                  dateTime: dayjs(
                    `${values.date}T${values.time}`
                  ).toISOString(),
                };
                const result = await handleAddBreakdownMaintenance(payload);
                if (result) {
                  resetForm();
                  setTimeout(() => {
                    navigate("/accident-breakdown");
                  }, 2000);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "rgba(105, 108, 255, 0.16)",
                            }}
                          >
                            <th className="col-sm-6">Particulars</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cssHandling ?
                            <tr className="table-active">
                              <td>Divison</td>
                              <td>
                                <div className="col-sm-9">
                                  <Field
                                    className="form-select"
                                    component="select"
                                    id="division"
                                    name="division"
                                    onChange={handleChange}
                                    onClick={() => getUserDepotLists(values.division)}
                                  >
                                    <option value="">Select Division</option>
                                    {divisionList &&
                                      divisionList.length > 0 &&
                                      divisionList.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </Field>
                                  {errors.division && touched.division ? (
                                    <div
                                      style={{ color: "red", marginTop: "2px" }}
                                    >
                                      {errors.division}
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                            : null}
                          <tr>
                            <td>Select Depot</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="depot"
                                  component="select"
                                  aria-label="Default select example"
                                  name="depot"
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    if (e.target.value) {
                                      getBusByDepotLink(e.target.value);
                                      setSelectedDepot(e.target.value);
                                    } else {
                                      setSelectedDepot(null);
                                    }
                                  }}
                                >
                                  <option value="">Select Depot</option>
                                  {
                                    cssHandling ?
                                      depotList &&
                                      depotList.length > 0 &&
                                      depotList.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))
                                      :
                                      depots &&
                                      depots.length > 0 &&
                                      depots.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))
                                  }
                                </Field>
                                {errors.depot && touched.depot ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.depot}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr className="table-active">
                            <td>Select Bus</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="exampleFormControlSelect1"
                                  aria-label="Default select example"
                                  name="bus"
                                  component="select"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Bus No</option>
                                  {selectedDepot !== null &&
                                    busList &&
                                    busList.length > 0 &&
                                    busList.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.registerationNumber}
                                      </option>
                                    ))}
                                </Field>
                                {errors.bus && touched.bus ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.bus}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Breakdown Inspection</td>
                            <td>
                              <div className="col-md">
                                <div className="form-check form-check-inline mt-3">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="breakdownInspection"
                                    value="CNG"
                                    onClick={() => {
                                      handleCNGRadioClick();
                                      if (state?.turbo) {
                                        values.turboCharger = true;
                                      }
                                    }}
                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    CNG Related
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="breakdownInspection"
                                    value="OTHER"
                                    onClick={() => {
                                      setCheckboxVisible(false);
                                      if (state?.turbo) {
                                        values.turboCharger = false;
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    MSRTC
                                  </label>
                                </div>
                                {errors.breakdownInspection &&
                                  touched.breakdownInspection ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.breakdownInspection}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          {checkboxVisible && (
                            <>
                              <tr className="sub-thead">
                                <th colSpan={2}>Reason of Breakdown</th>
                              </tr>

                              <tr>
                                <td>Gas Leakage</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="gasLeakageB"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.gasLeakageB}
                                      onChange={() =>
                                        setFieldValue(
                                          "gasLeakageB",
                                          !values.gasLeakageB
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Accidental Pedal</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="accidentalPedal"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.accidentalPedal}
                                      onChange={() =>
                                        setFieldValue(
                                          "accidentalPedal",
                                          !values.accidentalPedal
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Compressure Pipe</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="compressurePipe"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.compressurePipe}
                                      onChange={() =>
                                        setFieldValue(
                                          "compressurePipe",
                                          !values.compressurePipe
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Turbo Pipe</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="turboPipe"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.turboPipe}
                                      onChange={() =>
                                        setFieldValue(
                                          "turboPipe",
                                          !values.turboPipe
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Kit High Pressure Pipe</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="kitHighPressurePipe"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.kitHighPressurePipe}
                                      onChange={() =>
                                        setFieldValue(
                                          "kitHighPressurePipe",
                                          !values.kitHighPressurePipe
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Kit Low Pressure Pipe</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="kitLowPressurePipe"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.kitLowPressurePipe}
                                      onChange={() =>
                                        setFieldValue(
                                          "kitLowPressurePipe",
                                          !values.kitLowPressurePipe
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Compressure</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="compressure"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.compressure}
                                      onChange={() =>
                                        setFieldValue(
                                          "compressure",
                                          !values.compressure
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Turbo Charger</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="turboCharger"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.turboCharger}
                                      onChange={() =>
                                        setFieldValue(
                                          "turboCharger",
                                          !values.turboCharger
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Water Body</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="waterBody"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.waterBody}
                                      onChange={() =>
                                        setFieldValue(
                                          "waterBody",
                                          !values.waterBody
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>CNG Kit</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="cngKit"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.cngKit}
                                      onChange={() =>
                                        setFieldValue("cngKit", !values.cngKit)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Engine</td>
                                <td>
                                  <div className="row">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name="engine"
                                      style={{ marginLeft: "10px" }}
                                      checked={values.engine}
                                      onChange={() =>
                                        setFieldValue("engine", !values.engine)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="table-active">
                            <td>Remark</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  id="other"
                                  name="other"
                                  className="form-control"
                                  placeholder="Remark"
                                  onChange={handleChange}
                                  component="textarea"
                                  maxLength="300"
                                  rows="2"
                                />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Time & Date</td>
                            <td>
                              <div className="col-sm-9">
                                <div className="row">
                                  <div className="col-sm-6">
                                    <Field
                                      className="form-control"
                                      type="time"
                                      name="time"
                                      onChange={handleChange}
                                    />

                                    {errors.time && touched.time ? (
                                      <div
                                        style={{
                                          color: "red",
                                          marginTop: "2px",
                                        }}
                                      >
                                        {errors.time}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-sm-6">
                                    <Field
                                      className="form-control"
                                      type="date"
                                      name="date"
                                      onChange={handleChange}
                                      max={moment().format("YYYY-MM-DD")}
                                      value={values.date}
                                    />

                                    {errors.date && touched.date ? (
                                      <div
                                        style={{
                                          color: "red",
                                          marginTop: "2px",
                                        }}
                                      >
                                        {errors.date}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr className="table-active">
                            <td>Depot Brought To</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="exampleFormControlSelect1"
                                  aria-label="Default select example"
                                  name="depotBroughtTo"
                                  component="select"
                                  onChange={handleChange}
                                >
                                  <option value="">Select Depot</option>
                                  {depots &&
                                    depots.length > 0 &&
                                    depots.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </Field>
                                {errors.depotBroughtTo &&
                                  touched.depotBroughtTo ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.depotBroughtTo}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="demo-inline-spacing d-flex justify-content-center mt-5"
                      style={{ marginBottom: "10px", gap: "30px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBreakdownMaintenance;
