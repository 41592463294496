import "./App.css";
import AppRouter from "./router/AppRouter";
import { store } from "./store/store";
import axiosSetup from "./utills/axiosSetup";

function App() {
  axiosSetup(store);

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
