import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { handleAxiosError, matchBusNumber } from "../../utills/helper";
import Fuse from "fuse.js";
import {
  AccidentData,
  BreakdownData,
  IAccidentBreakdownDetail,
} from "../../interfaces/maintenance.interface";
import maintenanceService from "../../services/MaintenanceService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ViewAccidentBreakdown: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { busId: string };
  const [accidentBreakdownList, setAccidentBreakdownList] =
    useState<IAccidentBreakdownDetail>({ accidentData: [], breakdownData: [] });
  // const [accidentListMemo, setAccidentListmemo] = useState<AccidentData[]>([]);
  // const [breakdownListMemo, setBreakdownListmemo] = useState<BreakdownData[]>(
  //   []
  // );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBusAccident, setSelectedBusAccident] =
    useState<AccidentData>();
  const [selectedBusBreakdown, setSelectedBusBreakdown] =
    useState<BreakdownData>();
  const modalAccidentRef = useRef<HTMLDivElement>(null);

  const renderTooltip = (title: string) => (
    <Tooltip id="tooltip">
      <span>{title}</span>
    </Tooltip>
  );

  const getAccidentBreakdownList = async () => {
    setLoading(true);
    try {
      const { data } = await maintenanceService.getAccidentBreakdownListById({
        busIds: [state.busId],
      });
      setAccidentBreakdownList({
        accidentData: data.accidentData,
        breakdownData: data.breakdownData.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
      });
      setAccidentBreakdownList(data);
      // setAccidentListmemo(data.accidentData);
      // setBreakdownListmemo(data.breakdownData);
      setLoading(false);
    } catch (err) {
      handleAxiosError(err);
      setLoading(false);
    }
  };

  const [page, setPage] = useState<number>(1);

  const selectPageHandler = (selectedPage: number) => {
    if (
      selectedPage >= 1 &&
      selectedPage <=
        Math.ceil(Math.ceil(accidentBreakdownList.accidentData.length) / 10) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const [pageBreakdown, setPageBreakdown] = useState<number>(1);

  const selectPageHandlerBreakdown = (selectedPage: number) => {
    if (
      selectedPage >= 1 &&
      selectedPage <=
        Math.ceil(Math.ceil(accidentBreakdownList.breakdownData.length) / 10) &&
      selectedPage !== page
    ) {
      setPageBreakdown(selectedPage);
    }
  };

  // const debouncedSearch = _.debounce(
  //   (search, type: "Accident" | "Breakdown") => {
  //     // && /^[a-zA-Z0-9\s]+$/.test(search)
  //     let input = search.replace(/ /g, "");
  //     if (input && matchBusNumber(input)) {
  //       const options = {
  //         shouldSort: true,
  //         threshold: search.length >= 9 ? 0.1 : 0.2,
  //         location: 1000,
  //         distance: 100,
  //         includeMatches: true,
  //         minMatchCharLength: 1,
  //         includeScore: true,
  //         keys: ["bus.registerationNumber"],
  //       };
  //       const data = type === "Accident" ? accidentListMemo : breakdownListMemo;
  //       const fuse = new Fuse([...data], options);
  //       const result = fuse.search(input);
  //       if (result.length > 0) {
  //         setPage(1);
  //         setAccidentBreakdownList((prev) => {
  //           return {
  //             breakdownData: prev.breakdownData,
  //             accidentData: result.map((item) => item.item),
  //           } as IAccidentBreakdownDetail;
  //         });
  //       } else {
  //         setAccidentBreakdownList({ accidentData: [], breakdownData: [] });
  //       }
  //     } else {
  //       setAccidentBreakdownList({ accidentData: [], breakdownData: [] });
  //     }

  //     if (!search) {
  //       setAccidentBreakdownList((prev) => {
  //         return {
  //           breakdownData: prev.breakdownData,
  //           accidentData: accidentListMemo,
  //         };
  //       });
  //     }
  //   },
  //   300
  // );

  // const handleSearch = (search: string, type: "Accident" | "Breakdown") => {
  //   debouncedSearch(search, type);
  // };

  const [sortColumn, setSortColumn] =
    useState<keyof IAccidentBreakdownDetail>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const handleSort = (column: keyof IAccidentBreakdownDetail) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const [sortColumnAccident, setSortColumnAccident] =
    useState<keyof IAccidentBreakdownDetail>();
  const [sortOrderAccident, setSortOrderAccident] = useState<"asc" | "desc">(
    "asc"
  );

  const handleSortAccident = (column: keyof IAccidentBreakdownDetail) => {
    if (column === sortColumnAccident) {
      setSortOrderAccident(sortOrderAccident === "asc" ? "desc" : "asc");
    } else {
      setSortColumnAccident(column);
      setSortOrderAccident("asc");
    }
  };

  const getNestedPropertyValue = (obj: any, propertyPath: string) => {
    const properties = propertyPath.split(".");
    return properties.reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  const getSortIcon = (column: string) => {
    if (column === sortColumn) {
      return sortOrder === "asc" ? "bx-chevrons-up" : "bx-chevrons-down";
    }
    return "bx-chevrons-down";
  };

  const getSortAccidentIcon = (column: string) => {
    if (column === sortColumnAccident) {
      return sortOrderAccident === "asc"
        ? "bx-chevrons-down"
        : "bx-chevrons-up";
    }
    return "bx-chevrons-up";
  };

  const handleBack = () => {
    navigate("/accident-breakdown");
  };

  useEffect(() => {
    getAccidentBreakdownList();
  }, []);

  useEffect(() => {
    setAccidentBreakdownList((prevBusList) => {
      const sortedList = [...prevBusList.breakdownData].sort((a, b) => {
        if (sortColumn) {
          const aValue = getNestedPropertyValue(a, sortColumn);
          const bValue = getNestedPropertyValue(b, sortColumn);
          if (sortOrder === "asc") {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          } else {
            if (aValue > bValue) return -1;
            if (aValue < bValue) return 1;
            return 0;
          }
        } else {
          return 0;
        }
      });

      return {
        accidentData: prevBusList.accidentData,
        breakdownData: sortedList,
      } as IAccidentBreakdownDetail;
    });
  }, [sortOrder, sortColumn]);

  useEffect(() => {
    setAccidentBreakdownList((prevBusList) => {
      const sortedList = [...prevBusList.accidentData].sort((a, b) => {
        if (sortColumnAccident) {
          let startDate = new Date(a.dateTime);
          let endDate = new Date(b.dateTime);
          let sortOrder = sortOrderAccident === "asc" ? 1 : -1;

          return startDate > endDate
            ? -sortOrder
            : startDate < endDate
            ? sortOrder
            : 0;
        } else {
          return 0;
        }
      });

      return {
        ...prevBusList,
        accidentData: sortedList,
      };
    });
  }, [sortOrderAccident, sortColumnAccident]);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3">
          <span className="text-muted fw-light">
            View -{">"}Accident / Breakdown
          </span>
        </h4>
        <div className="col-lg-12 mb-4 order-0">
          <div className="card">
            <div className="card">
              <div
                className="table-responsive text-nowrap"
                data-bs-ride="Layouts"
              >
                <br />
                <div className="nav-align-top">
                  <ul className="nav nav-pills mx-4" role="tablist">
                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link active"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-pills-top-messages"
                        aria-controls="navs-pills-top-messages"
                        aria-selected="true"
                      >
                        Accident
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-pills-top-profile"
                        aria-controls="navs-pills-top-profile"
                        aria-selected="false"
                      >
                        Breakdown
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade"
                      id="navs-pills-top-profile"
                      role="tabpanel"
                    >
                      {/* <div className="d-flex justify-content-between my-3">
                        <div>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearch(e.target.value, "Breakdown");
                            }}
                          />
                        </div>
                      </div> */}
                      <table className="table">
                        <thead>
                          <tr
                            className="text-nowrap"
                            style={{
                              backgroundColor: "rgba(105, 108, 255, 0.16",
                            }}
                          >
                            <th>Bus No. </th>
                            <th>Depot</th>
                            <th
                              onClick={() =>
                                handleSort(
                                  "dateTime" as keyof IAccidentBreakdownDetail
                                )
                              }
                            >
                              Date
                              <i
                                className={`tf-icon bx ${getSortIcon(
                                  "dateTime"
                                )}`}
                              />
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            accidentBreakdownList &&
                            accidentBreakdownList.breakdownData.length > 0 &&
                            accidentBreakdownList.breakdownData
                              .slice(
                                pageBreakdown * 10 - 10,
                                pageBreakdown * 10
                              )
                              .map((item) => (
                                <tr key={item._id}>
                                  <td>{item?.bus.registerationNumber}</td>
                                  <td>{item?.depot.name}</td>

                                  <td>
                                    {dayjs(item.dateTime).format(
                                      "YYYY-MM-DD HH:mm"
                                    )}
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={renderTooltip("View")}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        // onClick={() => setSelectedBus(item)}
                                        onClick={() =>
                                          setSelectedBusBreakdown(item)
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#breakdownModel"
                                      >
                                        <span className="tf-icons bx bxs-show"></span>
                                      </button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                      <br />
                      {accidentBreakdownList.breakdownData &&
                        accidentBreakdownList.breakdownData.length <= 0 && (
                          <h4
                            className="data-found"
                            style={{
                              margin: "0 auto",
                              textAlign: "center",
                              fontWeight: "700",
                              paddingTop: "10px",
                            }}
                          >
                            No Records Found
                          </h4>
                        )}
                      <br />
                      {accidentBreakdownList.breakdownData.length > 10 && (
                        <nav aria-label="Page navigation">
                          <ul className="pagination justify-content-center">
                            <li className="page-item prev">
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                              >
                                <i
                                  className={`tf-icon bx bx-chevrons-left ${
                                    pageBreakdown > 1 ? "" : "disabled"
                                  }`}
                                  onClick={() =>
                                    selectPageHandlerBreakdown(
                                      pageBreakdown - 1
                                    )
                                  }
                                ></i>
                              </a>
                            </li>
                            {[
                              ...Array(
                                Math.ceil(
                                  accidentBreakdownList.breakdownData.length /
                                    10
                                )
                              ),
                            ].map((_, i) => {
                              if (
                                i === 0 ||
                                i ===
                                  Math.ceil(
                                    accidentBreakdownList.breakdownData.length /
                                      10
                                  ) -
                                    1 ||
                                (i >= pageBreakdown - 2 &&
                                  i <= pageBreakdown + 2)
                              ) {
                                return (
                                  <li
                                    className={`page-item ${
                                      pageBreakdown === i + 1 ? "active" : ""
                                    }`}
                                    key={i}
                                  >
                                    <a
                                      className="page-link"
                                      href="javascript:void(0);"
                                      onClick={() =>
                                        selectPageHandlerBreakdown(i + 1)
                                      }
                                    >
                                      {i + 1}
                                    </a>
                                  </li>
                                );
                              } else if (
                                i === pageBreakdown - 3 ||
                                i === pageBreakdown + 3
                              ) {
                                return (
                                  <li className="page-item disabled" key={i}>
                                    <a
                                      className="page-link"
                                      href="javascript:void(0);"
                                    >
                                      ...
                                    </a>
                                  </li>
                                );
                              }
                            })}
                            <li className="page-item next">
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                              >
                                <i
                                  className={`tf-icon bx bx-chevrons-right ${
                                    page <
                                    accidentBreakdownList.breakdownData.length /
                                      10
                                      ? ""
                                      : "disabled"
                                  }`}
                                  onClick={() =>
                                    selectPageHandlerBreakdown(
                                      pageBreakdown + 1
                                    )
                                  }
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      )}
                      <div
                        className="demo-inline-spacing d-flex justify-content-end"
                        style={{ marginBottom: 10, gap: 30 }}
                      >
                        <button
                          type="button"
                          onClick={handleBack}
                          className="btn btn-primary"
                        >
                          Back
                        </button>
                      </div>
                    </div>

                    {/* Accident */}
                    <div
                      className="tab-pane fade active show"
                      id="navs-pills-top-messages"
                      role="tabpanel"
                    >
                      {/* <div className="d-flex justify-content-between my-3">
                        <div>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearch(e.target.value, "Accident");
                            }}
                          />
                        </div>
                      </div> */}
                      <table className="table">
                        <thead>
                          <tr
                            className="text-nowrap"
                            style={{
                              backgroundColor: "rgba(105, 108, 255, 0.16",
                            }}
                          >
                            <th>Bus No. </th>
                            <th>Depot</th>
                            <th
                              onClick={() =>
                                handleSortAccident(
                                  "dateTime" as keyof IAccidentBreakdownDetail
                                )
                              }
                            >
                              Date
                              <i
                                className={`tf-icon bx ${getSortAccidentIcon(
                                  "dateTime"
                                )}`}
                              />
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            accidentBreakdownList &&
                            accidentBreakdownList.accidentData.length > 0 &&
                            accidentBreakdownList.accidentData
                              .slice(page * 10 - 10, page * 10)
                              .map((item) => (
                                <tr key={item._id}>
                                  <td>{item?.bus.registerationNumber}</td>
                                  <td>{item?.depot.name}</td>

                                  <td>
                                    {dayjs(item.dateTime).format("YYYY-MM-DD")}
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={renderTooltip("View")}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() =>
                                          setSelectedBusAccident(item)
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#accidentModal"
                                      >
                                        <span className="tf-icons bx bxs-show"></span>
                                      </button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                      <br />
                      {accidentBreakdownList.accidentData &&
                        accidentBreakdownList.accidentData.length <= 0 && (
                          <h4
                            className="data-found"
                            style={{
                              margin: "0 auto",
                              textAlign: "center",
                              fontWeight: "700",
                              paddingTop: "10px",
                            }}
                          >
                            No Records Found
                          </h4>
                        )}
                      <br />
                      {accidentBreakdownList.accidentData.length > 10 && (
                        <nav aria-label="Page navigation">
                          <ul className="pagination justify-content-center">
                            <li className="page-item prev">
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                              >
                                <i
                                  className={`tf-icon bx bx-chevrons-left ${
                                    page > 1 ? "" : "disabled"
                                  }`}
                                  onClick={() => selectPageHandler(page - 1)}
                                ></i>
                              </a>
                            </li>
                            {[
                              ...Array(
                                Math.ceil(
                                  accidentBreakdownList.accidentData.length / 10
                                )
                              ),
                            ].map((_, i) => {
                              if (
                                i === 0 ||
                                i ===
                                  Math.ceil(
                                    accidentBreakdownList.accidentData.length /
                                      10
                                  ) -
                                    1 ||
                                (i >= page - 2 && i <= page + 2)
                              ) {
                                return (
                                  <li
                                    className={`page-item ${
                                      page === i + 1 ? "active" : ""
                                    }`}
                                    key={i}
                                  >
                                    <a
                                      className="page-link"
                                      href="javascript:void(0);"
                                      onClick={() => selectPageHandler(i + 1)}
                                    >
                                      {i + 1}
                                    </a>
                                  </li>
                                );
                              } else if (i === page - 3 || i === page + 3) {
                                return (
                                  <li className="page-item disabled" key={i}>
                                    <a
                                      className="page-link"
                                      href="javascript:void(0);"
                                    >
                                      ...
                                    </a>
                                  </li>
                                );
                              }
                            })}
                            <li className="page-item next">
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                              >
                                <i
                                  className={`tf-icon bx bx-chevrons-right ${
                                    page <
                                    accidentBreakdownList.accidentData.length /
                                      10
                                      ? ""
                                      : "disabled"
                                  }`}
                                  onClick={() => selectPageHandler(page + 1)}
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      )}
                      <div
                        className="demo-inline-spacing d-flex justify-content-end"
                        style={{ marginBottom: 10, gap: 30 }}
                      >
                        <button
                          type="button"
                          onClick={handleBack}
                          className="btn btn-primary"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="accidentModal"
        tabIndex={-1}
        aria-hidden="true"
        // ref={modalAccidentRef}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel3">
                Accident Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "rgba(105, 108, 255, 0.16)",
                      }}
                    >
                      <th className="col-sm-6">Particulars</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Depot</td>
                      <td>{selectedBusAccident?.depot?.name}</td>
                    </tr>

                    <tr className="table-active">
                      <td>Bus</td>
                      <td>
                        <div className="col-sm-9">
                          {selectedBusAccident?.bus.registerationNumber}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Repair</td>
                      <td>
                        <div className="col-sm-9">
                          {selectedBusAccident?.repair}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Date</td>
                      <td>
                        <div className="col-sm-9">
                          {dayjs(selectedBusAccident?.dateTime).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr className="table-active">
                      <td>Remark</td>
                      <td>{selectedBusAccident?.remark}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <>
                <span className="text-muted fw-light">CNG Parts</span>

                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr
                        style={{
                          backgroundColor: "rgba(105, 108, 255, 0.16)",
                        }}
                      >
                        <th className="col-sm-6">Particulars</th>
                        <th>Company</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBusAccident?.CNGParts &&
                        selectedBusAccident?.CNGParts.length > 0 &&
                        selectedBusAccident?.CNGParts.map((item, index) => (
                          <tr
                            className={index % 2 === 0 ? "table-active" : ""}
                            key={item._id}
                          >
                            {/* <td>1</td> */}
                            <td>
                              <label
                                className="col-sm-2 col-form-label"
                                htmlFor="basic-default-name"
                                key={item._id}
                              >
                                {item?.partId?.name}
                              </label>
                            </td>
                            <td>
                              <div className="col-sm-9">
                                {item?.manufacturerId?.name}
                              </div>
                            </td>
                          </tr>
                        ))}

                      <tr className={"table-active"}>
                        <td>
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="basic-default-name"
                          >
                            Engine No. (CNG Type)
                          </label>
                        </td>
                        <td>
                          <div className="col-sm-9">
                            {selectedBusAccident?.ConvertedEngineNo}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="basic-default-name"
                          >
                            Battery Rating (V/Ah) with Battery No.
                          </label>
                        </td>
                        <td>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-sm-6">
                                {selectedBusAccident?.BatteryRatingOne}
                              </div>
                              <div className="col-sm-6">
                                {selectedBusAccident?.BatteryRatingTwo}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className={"table-active"}>
                        <td>
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="basic-default-name"
                          >
                            Spare Tyre Cage/ Bracket (Please mention the
                            location)
                          </label>
                        </td>
                        <td>
                          <div className="col-sm-9">
                            {selectedBusAccident?.SpareTyreCage}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="basic-default-name"
                          >
                            CNG in Kg in CNG tank at the time of accepting the
                            vehicle
                          </label>
                        </td>
                        <td>
                          <div className="col-sm-9">
                            {selectedBusAccident?.cngInKG}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="breakdownModel"
        tabIndex={-1}
        aria-hidden="true"
        ref={modalAccidentRef}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel3">
                Breakdown Detail
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "rgba(105, 108, 255, 0.16)",
                      }}
                    >
                      <th className="col-sm-6">Particulars</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Select Depot</td>
                      <td>{selectedBusBreakdown?.depot?.name}</td>
                    </tr>

                    <tr className="table-active">
                      <td>Select Bus</td>
                      <td>{selectedBusBreakdown?.bus?.registerationNumber}</td>
                    </tr>

                    <tr>
                      <td>Breakdown Inspection</td>
                      <td>{selectedBusBreakdown?.depot?.name}</td>
                    </tr>

                    {selectedBusBreakdown?.breakdownInspection === "CNG" && (
                      <>
                        <tr className="sub-thead">
                          <th colSpan={2}>Reason of Breakdown</th>
                        </tr>

                        <tr>
                          <td>Gas Leakage</td>
                          <td>
                            {selectedBusBreakdown?.gasLeakageB
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Accidental Pedal</td>
                          <td>
                            {selectedBusBreakdown?.accidentalPedal
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Compressure Pipe</td>
                          <td>
                            {selectedBusBreakdown?.compressurePipe
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Turbo Pipe</td>
                          <td>
                            {selectedBusBreakdown?.turboPipe
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Kit High Pressure Pipe</td>
                          <td>
                            {selectedBusBreakdown?.kitHighPressurePipe
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Kit Low Pressure Pipe</td>
                          <td>
                            {selectedBusBreakdown?.kitLowPressurePipe
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Compressure</td>
                          <td>
                            {selectedBusBreakdown?.compressure
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Turbo Charger</td>
                          <td>
                            {selectedBusBreakdown?.turboCharger
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Water Body</td>
                          <td>
                            {selectedBusBreakdown?.waterBody
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>CNG Kit</td>
                          <td>
                            {selectedBusBreakdown?.cngKit
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                        <tr>
                          <td>Engine</td>
                          <td>
                            {selectedBusBreakdown?.engine
                              ? "Checked"
                              : "Not Checked"}
                          </td>
                        </tr>
                      </>
                    )}

                    <tr className="table-active">
                      <td>Remark</td>
                      <td>{selectedBusBreakdown?.other}</td>
                    </tr>

                    <tr>
                      <td>Time & Date</td>
                      <td>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-sm-6">
                              {dayjs(selectedBusBreakdown?.dateTime).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="table-active">
                      <td>Depot Brought To</td>
                      <td>{selectedBusBreakdown?.depotBroughtTo?.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAccidentBreakdown;
