import * as yup from "yup";

export const AddIssueSchema = yup.object({
  issueDescription: yup
    .string()
    .trim()
    .required("Issue Description required")
    .min(1)
    .max(500),
  bus: yup.string().required("Select Bus"),
  depot: yup.string().required("Select Depot"),
  division: yup.string().required("Select Division"),
  issueStatus: yup.string().required("Select Issue Status"),
});
