import * as yup from "yup";

export const DailyMaintenanceSchema = yup.object({
  depot: yup.string().trim().required("Depot Name Required"),
  bus: yup.string().trim().required("Bus Number Required"),
  whileTakenOver: yup
    .number()
    .positive("Please enter a positive number")
    .typeError("Value must be a Number")
    .test("decimal-check", "Enter a number up to two decimal", (value) => {
      if (value !== undefined && value !== null) {
        const decimalCount = value.toString().split(".")[1]?.length || 0;
        const integerPart = value.toString().split(".")[0];
        return decimalCount <= 2 && integerPart.length <= 7;
      }
      return true;
    }),
  whileHandedOver: yup
    .number()
    .positive("Please enter a positive number")
    .typeError("Value must be a Number")
    // .test(
    //   "greater-than-taken-over",
    //   "Value must be greater than Taken Over",
    //   function (value) {
    //     const { whileTakenOver } = this.parent; // Access the value of whileTakenOver
    //     if (value) {
    //       return value >= whileTakenOver;
    //     }
    //   }
    // )
    .test("decimal-check", "Enter a number up to two decimal", (value) => {
      if (value !== undefined && value !== null) {
        const decimalCount = value.toString().split(".")[1]?.length || 0;
        const integerPart = value.toString().split(".")[0];
        return decimalCount <= 2 && integerPart.length <= 7;
      }
      return true;
    }),
  oil: yup.string().trim().required("Litre Required"),
  turbo: yup.string().trim().required("Turbo Required"),
  dateTime: yup
    .date()
    .min(new Date("1970-01-01"), "Please Choose Valid Date")
    .max(new Date(), "Please Choose Valid Date")
    .required("Enter Date"),
});

export const BreakDownMaintenanceSchema = yup.object({
  depot: yup.string().trim().required("Depot Name Required"),
  bus: yup.string().trim().required("Bus Number Required"),
  breakdownInspection: yup.string().required("BreakDown Inspection Required"),
  time: yup.string().trim().required("Time Required"),
  date: yup
    .date()
    .min(new Date("1970-01-01"), "Please choose valid date")
    .max(new Date("2099-01-01"), "Please choose valid date")
    .required("Date Required"),
  depotBroughtTo: yup.string().trim().required("Depot Brought To Required"),
});

export const AccidentMaintenanceSchema = yup.object({
  depot: yup.string().trim().required("Depot Required"),
  bus: yup.string().trim().required("Bus Number Required"),
  repair: yup.string().trim().required("Repair Required"),
  dateTime: yup
    .date()
    .min(new Date("1970-01-01"), "Please Choose Valid Date")
    .max(new Date(), "Please Choose Valid Date")
    .required("Enter Date"),
});

export const BreakDownMaintenanceWithDivision = BreakDownMaintenanceSchema.shape({
  division: yup.string().trim().required(`Select Division`),
})

export const BreakDownMaintenanceWithoutDivision = BreakDownMaintenanceSchema

export const AccidentMaintenanceWithDivision = AccidentMaintenanceSchema.shape({
  division: yup.string().trim().required(`Select Division`),
})

export const AccidentMaintenanceWithoutDivision = AccidentMaintenanceSchema
