import axios from "axios";

export interface IIssueStatus {
  _id: string;
  name: string;
}

const issueStatusService = {
  getAllIssueStatuses: () => {
    return axios.get("/issueStatus");
  },

  getIssueStatusById: (statusId: string) => {
    return axios.get(`/issueStatus/${statusId}`);
  },
};

export default issueStatusService;
