import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { notificationController } from "./toastNotification";
import { readToken } from "../services/LocalStorageService";
import { doLogout } from "../store/slices/authSlice";

export default function axiosSetup(store: any): typeof axios {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig<AxiosRequestHeaders>) => {
      const token = readToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["Content-Type"] = `application/json`;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError<any>) => {
      if (error.message === "Network Error") {
        notificationController.warning(`Network Error, 'Unable to connect`);
      }

      if (error.response && error.response.status === 401) {
        store.dispatch(doLogout());

        notificationController.warning(
          `${
            error.response.data.message === "Password is Incorrect!"
              ? "The Username or Password you entered is Incorrect"
              : error.response.data.message
          }`
        );
      } else if (error.response && error.response.status === 422) {
        if (Array.isArray(error?.response?.data)) {
          return notificationController.error(`Validation Error`);
        } else {
          notificationController.error(`${error?.response?.data?.message[0]}`);
        }
      } else {
        // Do something with request error
        return Promise.reject(error);
      }
    }
  );

  return axios;
}
