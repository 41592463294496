import axios from "axios";
import { IDashboardList } from "../interfaces/dashboard.interface";

const dashboardService = {
  getDashboardData: async (): Promise<{ data: IDashboardList }> => {
    const result = await axios.get("/dashboard");
    return result.data;
  },
};

export default dashboardService;
