import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notificationController } from "../../../utills/toastNotification";
import { Formik, Form, Field } from "formik";
import { handleAxiosError } from "../../../utills/helper";
import IssueService, { IAddIssue } from "../../../services/IssueService";
import busService from "../../../services/BusService";
import { useDepotList } from "../../../hooks/useDepotList";
import depotService from "../../../services/DepotService";
import divisionService from "../../../services/DivisionService";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { Roles } from "../../../interfaces/role.interface";
import issueStatusService, {
  IIssueStatus,
} from "../../../services/IssueStatusService";
import { AddIssueSchema } from "../../../schemas/addissue.schema";
import { format } from "date-fns"; // Import the format function

const AddIssue: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state: any) => state.user.user);
  const cssHandling = user?.role !== Roles.DIVISION;
  const { depots } = useDepotList();
  const [busList, setBusList] = useState<any>();
  const [defaultValue, setDefaultValue] = useState("");
  const [showBusList, setShowBusList] = useState<boolean>(false);
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [divisionList, setDivisionList] = useState<any>();
  const [depotList, setDepotList] = useState<any>(null);
  const [issueStatusList, setIssueStatusList] = useState<IIssueStatus[]>([]);
  const [issueDate, setIssueDate] = useState(format(new Date(), "yyyy-MM-dd")); // Set default value to today's date

  const getBusByDepotLink = async (busId: string) => {
    try {
      if (busId) {
        const { data } = await depotService.getDepotDependentBusList(busId);
        if (data.length > 0) {
          setShowBusList(true);
          setBusList(data);
        } else {
          setBusList([]);
        }
      } else {
        setBusList([]);
      }
    } catch (error) {
      setShowBusList(false);
      handleAxiosError(error);
    }
  };

  const getBusData = async () => {
    try {
      const { data } = await busService.getBus();
      setBusList(data);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const getIssueStatusData = async () => {
    try {
      const { data } = await issueStatusService.getAllIssueStatuses();
      // Filter the status list to include only PENDING
      const pendingStatus = data.find(
        (status: IIssueStatus) => status.name === "PENDING"
      );

      setDefaultValue(
        [pendingStatus].length == 1 ? [pendingStatus][0]._id : ""
      );
      setIssueStatusList(pendingStatus ? [pendingStatus] : []);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const getDivisionData = async () => {
    try {
      const { data } = await divisionService.getDivisionList();
      setDivisionList(data);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const getUserDepotLists = async (value: any) => {
    if (value !== null) {
      const { data } = await depotService.getUserDepotList(value);
      setDepotList(data);
    }
  };

  useEffect(() => {
    getBusData();
    getDivisionData();
    getIssueStatusData();
  }, []);

  useEffect(() => {
    if (depots.length === 1) {
      getBusByDepotLink(depots[0]._id);
      setSelectedDepot(depots[0]._id as any);
    }
  }, [depots]);

  const handleAddIssue = async (data: IAddIssue) => {
    const result = {
      depot: data.depot,
      bus: data.bus,
      issueStatus: data.issueStatus,
      issueDescription: data.issueDescription,
      division: data.division,
      issueDate: data.issueDate,
    };
    try {
      await IssueService.addIssue(result);
      notificationController.success("Issue Added Successfully");
      setTimeout(() => {
        navigate("/issue-master");
      }, 1000);
      return true;
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="fw-bold py-3 mb-4">
        <span className="text-muted fw-light">Add Issue</span>
      </h4>
      {/* <!-- Basic Layout & Basic with Icons --> */}
      <div className="row">
        <div className="card">
          <Formik
            initialValues={{
              bus: "",
              issueStatus: "",
              issueDescription: "",
              depot: "",
              division: "",
              issueDate: issueDate, // Set the initial value of the "Issue Date" field
            }}
            validationSchema={AddIssueSchema}
            onSubmit={async (values) => {
              await handleAddIssue(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <div className="table-responsive text-nowrap">
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "rgba(105, 108, 255, 0.16)",
                          }}
                        >
                          <th className="col-sm-6">Issue</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={cssHandling ? "table-active" : ""}>
                          <td>Issue Date</td>
                          <td>
                            <div className="col-sm-9">
                              <Field
                                type="date"
                                className="form-control"
                                id="issueDate"
                                name="issueDate"
                                value={issueDate}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setIssueDate(e.target.value)}
                                disabled
                              />
                            </div>
                          </td>
                        </tr>
                        {cssHandling ? (
                          <tr className="table-active">
                            <td>Divison</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  component="select"
                                  id="division"
                                  name="division"
                                  onChange={(e: any) => {
                                    setFieldValue("issueStatus", defaultValue);
                                    handleChange(e);
                                    getUserDepotLists(e.target.value); // Call getUserDepotLists when division is selected
                                  }}
                                >
                                  <option value="">Select Division</option>
                                  {divisionList &&
                                    divisionList.map((item: any) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                </Field>
                                {errors.division && touched.division ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.division}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ) : null}
                        <tr className={cssHandling ? "" : "table-active"}>
                          <td>Select Depot</td>
                          <td>
                            <div className="col-sm-9">
                              <Field
                                className="form-select"
                                id="depot"
                                component="select"
                                aria-label="Default select example"
                                name="depot"
                                onChange={(e: any) => {
                                  handleChange(e);
                                  if (e.target.value) {
                                    getBusByDepotLink(e.target.value);
                                    setSelectedDepot(e.target.value);
                                  } else {
                                    setSelectedDepot(null);
                                  }
                                }}
                              >
                                <option value="">Select Depot</option>
                                {cssHandling
                                  ? values.division &&
                                    depotList &&
                                    depotList.length > 0 &&
                                    depotList.map((item: any) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))
                                  : depots &&
                                    depots.length > 0 &&
                                    depots.map((item: any) => (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                              </Field>
                              {errors.depot && touched.depot ? (
                                <div style={{ color: "red", marginTop: "2px" }}>
                                  {errors.depot}
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                        <tr className={cssHandling ? "table-active" : ""}>
                          <td>Select Bus</td>
                          <td>
                            <div className="col-sm-9">
                              <Field
                                className="form-select"
                                id="exampleFormControlSelect1"
                                aria-label="Default select example"
                                name="bus"
                                component="select"
                                onChange={handleChange}
                              >
                                <option value="">Select Bus</option>
                                {selectedDepot !== null &&
                                  showBusList &&
                                  busList &&
                                  busList.length > 0 &&
                                  values.division &&
                                  values.depot && // Only render options if both "Division" and "Depot" are selected
                                  busList.map((item: any) => (
                                    <option value={item._id} key={item._id}>
                                      {item.registerationNumber}
                                    </option>
                                  ))}
                              </Field>
                              {errors.bus && touched.bus ? (
                                <div style={{ color: "red", marginTop: "2px" }}>
                                  {errors.bus}
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>

                        <tr className={cssHandling ? "" : "table-active"}>
                          <td>Issue Status</td>
                          <td>
                            <div className="col-sm-9">
                              <Field
                                component="select"
                                name="issueStatus"
                                className="form-select"
                                id="issueStatus"
                                aria-label="Default select example"
                                onChange={handleChange}
                                disabled
                              >
                                <option value="">PENDING</option>
                                {issueStatusList.map((status: any) => (
                                  <option key={status._id} value={status._id}>
                                    {status.name}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </td>
                        </tr>
                        <tr className={cssHandling ? "table-active" : ""}>
                          <td>Issue Description</td>
                          <td>
                            <div className="col-sm-9">
                              <Field
                                name="issueDescription"
                                type="text"
                                className="form-control"
                                id="issueDescription"
                                onChange={handleChange}
                                placeholder="Enter Issue Description"
                              />
                              {errors.issueDescription &&
                              touched.issueDescription ? (
                                <div style={{ color: "red", marginTop: "2px" }}>
                                  {errors.issueDescription}
                                </div>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="demo-inline-spacing d-flex justify-content-center"
                    style={{ marginBottom: "10px", gap: "30px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        navigate("/issue-master");
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddIssue;
