import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { WithChildrenProps } from "../types/generalTypes";
import { deleteToken, readToken } from "../services/LocalStorageService";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { doLogout } from "../store/slices/authSlice";

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = readToken();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const siteMaintenance = process.env.REACT_APP_SITE_UNDER_MAINTENANCE;

  window.addEventListener("storage", (e) => {
    if (e.key === "authToken" && e.oldValue && !e.newValue) {
      if (token) {
        deleteToken();
      }
      navigate("/login");
    }
  });
  if (_.isEmpty(user?.permissions)) {
    dispatch(doLogout());
  }
  return token ? (
    <>{children}</>
  ) : siteMaintenance === "true" ? (
    <Navigate to="/under-maintenace" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default RequireAuth;
