import axios from "axios";

export interface IDivisionList {
    _id: string;
    name: string;
    address: string;
}

const divisionService = {
    getDivisionList: async (): Promise<{ data: IDivisionList[] }> => {
        const result = await axios.get("/master/division");
        return result.data;
    },

    getDivisionById: async (divisionId: string): Promise<{ data: IDivisionList }> => {
        const response = await axios.get(`/master/division/${divisionId}`);
        return response.data;
    },

    addDivision: async (data: { name: string; address: string }) => {
        return await axios.post("/master/division", data);
    },

    updateDivision: async (
        divisionId: string,
        data: { name: string; address: string }
    ) => {
        return await axios.put(`/master/division/${divisionId}`, data);
    },

    deleteDivision: async (divisionId: string) => {
        return await axios.delete("/master/division", {
            data: {
                _ids: [divisionId],
            },
        });
    },
};

export default divisionService;