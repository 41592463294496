import axios from "axios";
import {
  IAccidentMaintenance,
  IBreakdownMaintenance,
  IDailyMaintenance,
  IMonthlyMaintenance,
  IOil,
  IWeeklyMaintenance,
  IMaintenaceBusHistory,
  IMaintenanceDashboard,
  IMaintenaceLogs,
  IAccidentBreakdownDetail,
} from "../interfaces/maintenance.interface";

export interface IAccidentMaintenanceList {
  _id: string;
  registerationNumber: string;
  depot: string;
}

const maintenanceService = {
  getOils: async (): Promise<{ data: IOil[] }> => {
    const result = await axios.get("/maintenance/oil");
    return result.data;
  },

  getMaintenaceDashboard: async (): Promise<{
    data: IMaintenanceDashboard;
  }> => {
    const result = await axios.get("/maintenance/dashboard");
    return result.data;
  },

  addDailyMaintenance: (data: IDailyMaintenance) => {
    return axios.post("/maintenance/daily", data);
  },

  addWeeklyMaintenance: (data: IWeeklyMaintenance) => {
    return axios.post("/maintenance/weekly", data);
  },

  addMonthlyMaintenance: (data: IMonthlyMaintenance) => {
    return axios.post("/maintenance/monthly", data);
  },

  addBreakdownMaintenance: (data: IBreakdownMaintenance) => {
    return axios.post("/maintenance/breakdown", data);
  },

  addAccidentMaintenance: (data: IAccidentMaintenance) => {
    return axios.post("/maintenance/accident", data);
  },

  getMaintenanceHistory: async (
    busId: string
  ): Promise<{ data: IMaintenaceBusHistory[] }> => {
    const result = await axios.get(`/maintenance/history/${busId}`);
    return result.data;
  },

  getMaintenanceLogs: async (): Promise<{ data: IMaintenaceLogs[] }> => {
    const result = await axios.get("/maintenance/log");
    return result.data;
  },

  getAccidentBreakdownList: async (): Promise<{
    data: IAccidentMaintenanceList[];
  }> => {
    const result = await axios.get("/maintenance/accidentbreakdownbuslisting");
    return result.data;
  },

  getAccidentBreakdownListById: async (data: {
    busIds: string[];
  }): Promise<{ data: IAccidentBreakdownDetail }> => {
    const result = await axios.post("/maintenance/accidentbreakdowndata", data);
    return result.data;
  },

  getClientsLog: async (busId: string, type: string): Promise<{ data: any }> => {
    const result = await axios.get(`/client/log/detail/${busId}?type=${type}`);
    return result.data;
  },

  getClientsBus: async (): Promise<{ data: any }> => {
    const result = await axios.get("/client/log/bus");
    return result.data;
  },
};

export default maintenanceService;
