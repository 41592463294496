import React from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { readToken } from "../services/LocalStorageService";
import { Roles } from "../interfaces/role.interface";
import { permissionValidate } from "../utills/helper";
import _ from "lodash";
import { doLogout } from "../store/slices/authSlice";

interface IProps {
  allowRoles: Roles[];
  permissions: string[];
  children: React.ReactNode;
}

const RoleBasedAuth: React.FC<IProps> = ({
  children,
  allowRoles,
  permissions,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const accessToken = readToken();
  const siteMaintenance = process.env.REACT_APP_SITE_UNDER_MAINTENANCE;

  if (_.isEmpty(user?.permissions) || siteMaintenance === "true") {
    dispatch(doLogout());
  }

  return accessToken &&
    user &&
    permissionValidate(permissions, user.permissions) ? (
    <>{children}</>
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default RoleBasedAuth;
