import {
  MaintenancePermission,
  MasterPermission,
  OperationPermission,
  ReportsPermission,
  SidebarPermission,
} from "../../../utills/permissions";

export interface SidebarNavigationItem {
  title: string;
  key: string;
  permissions: string[];
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "Dashboard",
    key: "dashboard",

    permissions: [SidebarPermission.VENDOR_DASHBAORD],
    url: "/",
    icon: "bx-home-circle",
  },
  {
    title: "Client-Dashboard",
    key: "client-dashboard",
    url: "/client-dashboard",

    permissions: [SidebarPermission.CLIENT_DASHBAORD],
    icon: "bx-home-circle",
  },
  {
    title: "Masters",
    key: "medical-dashboard",
    children: [
      {
        title: "Bus Master",
        key: "bus-master",
        url: "/bus-master",

        permissions: [SidebarPermission.BUS_MASTER],
      },
      {
        title: "Driver Master",
        key: "driver-master",
        url: "/driver-master",
        permissions: [SidebarPermission.DRIVER_MASTER],
      },
      {
        title: "Route Master",
        key: "route-master",
        url: "/route-master",
        permissions: [SidebarPermission.ROUTE_MASTER],
      },
      {
        title: "User Master",
        key: "user-master",
        url: "/user-master",

        permissions: [SidebarPermission.USER_MASTER],
      },
      {
        title: "Role Master",
        key: "role-master",
        url: "/role-master",

        permissions: [SidebarPermission.ROLE_MASTER],
      },
      {
        title: "Division Master",
        key: "division-master",
        url: "/division-master",

        permissions: [SidebarPermission.DIVISION_MASTER],
      },
      {
        title: "Depot Master",
        key: "depot-master",
        url: "/depot-master",

        permissions: [SidebarPermission.DEPOT_MASTER],
      },
      {
        title: "Parts master",
        key: "parts-master",
        url: "/parts-master",

        permissions: [SidebarPermission.PARTS_MASTER],
      },
      {
        title: "Manufacturer Master",
        key: "manufacturer-master",
        url: "/manufacturer-master",

        permissions: [SidebarPermission.MANUFACTURER_MASTER],
      },
      {
        title: "Maintenance Observation Master",
        key: "maintenance-observation-master",
        url: "/maintenance-observation-master",

        permissions: [SidebarPermission.MAINTENANCE_OBSERVATION_MASTER],
      },
    ],
    icon: "bx-layout",

    permissions: [SidebarPermission.MASTERS],
  },
  {
    title: "Add Bus",
    key: "add-bus",
    url: "/add-bus",

    permissions: [MasterPermission.ADD_BUS],
    icon: "bxs-bus",
  },
  {
    title: "Pre-check",
    key: "pre-check",
    url: "/pre-check",

    permissions: [SidebarPermission.PRE_CHECK],
    icon: "bx-check",
  },
  {
    title: "WIP Parts Awaited",
    key: "wip-parts-awaited-vendor",
    url: "/wip-parts-awaited",

    permissions: [SidebarPermission.WIP_PARTS_AWAITED],
    icon: "bx-layout",
  },
  {
    title: "WIP",
    key: "wip",
    url: "/wip",
    permissions: [SidebarPermission.WIP],
    icon: "bxs-watch",
  },
  {
    title: "Completed",
    key: "completed",
    url: "/completed",
    permissions: [SidebarPermission.WIP_COMPLETED],
    icon: " bx-calendar-check",
  },
  {
    title: "Handed Over",
    key: "handed-over",
    url: "/handed-over",
    permissions: [SidebarPermission.HAND_OVER],
    icon: "bx-transfer",
  },
  {
    title: "Generate Invoice",
    key: "generate-invoice",
    url: "/generate-invoice",
    icon: "bx-home-circle",
    permissions: [SidebarPermission.UPDATE_INVOICE],
  },
  {
    title: "Unpaid Status",
    key: "unpaid-status",
    url: "/unpaid-status",
    icon: "bxs-bank",
    permissions: [SidebarPermission.UPDATE_PAYMENT],
  },
  {
    title: "Payment Received",
    key: "payment-received",
    url: "/payment-received",
    icon: "bxs-bank",
    permissions: [SidebarPermission.UPDATE_PAYMENT],
  },

  {
    title: "Bulk Upload",
    key: "bulk-upload",
    url: "/bulk-upload",
    icon: "bx-upload",
    permissions: [MasterPermission.ADD_BUS],
  },

  {
    title: "Maintenance",
    key: "maintenance",
    icon: "bx-transfer",
    children: [
      {
        title: "Dashboard",
        key: "maintenance-dashboard",
        url: "/maintenance",
        icon: "bx-layout",
        permissions: [MaintenancePermission.GET_MAINTENANCE_DASHBOARD],
      },
      {
        title: "Add Logs",
        key: "logs",
        url: "/maintenance-logs",
        icon: "bx-layout",
        permissions: [MaintenancePermission.GET_MAINTENANCE_LOG],
      },
      {
        title: "View Logs",
        key: "view-logs",
        url: "/view-maintenance-logs",
        icon: "bx-layout",
        permissions: [MaintenancePermission.CLIENT_VIEW_MAINTENANCE_LOG],
      },
      {
        title: "Accident/Breakdown",
        key: "accident-breakdown",
        url: "/accident-breakdown",
        icon: "bx-layout",
        permissions: [
          MaintenancePermission.GET_BREAKDOWN_ACCIDENT_BUS_DATA,
          MaintenancePermission.GET_BREAKDOWN_ACCIDENT_DATA,
        ],
      },
      {
        title: "History",
        key: "history-maintenance",
        url: "/history-maintenance",
        icon: "bx-layout",
        permissions: [MaintenancePermission.GET_MAINTENANCE_HISTORY],
      },
    ],
    permissions: [MaintenancePermission.GET_MAINTENANCE_DASHBOARD],
  },
  {
    title: "Reports",
    key: "reports",
    icon: "bx-layout",
    children: [
      {
        title: "Dashboard",
        key: "reports",
        url: "/reports",
        icon: "bx-layout",
        permissions: [ReportsPermission.GET_REPORTS],
      },
      {
        title: "Add Daily Report",
        key: "add-reports",
        url: "/add-reports",
        icon: "bx-layout",
        permissions: [ReportsPermission.ADD_REPORTS],
      },
      {
        title: "View Daily Report",
        key: "view-reports",
        url: "/view-reports",
        icon: "bx-layout",
        permissions: [ReportsPermission.GET_REPORTS],
      },
      {
        title: "Download Report",
        key: "print-reports",
        url: "/print-reports",
        icon: "bx-layout",
        permissions: [ReportsPermission.GET_REPORTS],
      },
    ],
    permissions: [SidebarPermission.SHOW_REPORTS],
  },
  {
    title: "Operation",
    key: "operation",
    url: "/operation",
    icon: "bx-layout",
    permissions: [OperationPermission.GET_OPERATION_WITH_START_KM],
  },
  {
    title: "Issue Master",
    key: "issue-master",
    url: "/issue-master",
    icon: "bx-chat",
    permissions: [SidebarPermission.ISSUE_MASTER],
  },
];
