import React, { useEffect, useRef, useState } from "react";
import {
  SidebarNavigationItem,
  sidebarNavigation,
} from "./sider/sideBarNavigation";
import { Link, Navigate, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { useAppSelector } from "../../hooks/reduxHooks";
import { Roles } from "../../interfaces/role.interface";
import { permissionValidate } from "../../utills/helper";

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(
      current.children && current.children.length > 0
        ? current.children
        : current
    ),
  []
);

const Sidebar: React.FC = () => {
  const location = useLocation();
  const masterUrl = [
    "/bus-master",
    "/user-master",
    "/role-master",
    "/depot-master",
    "/parts-master",
    "/manufacturer-master",
  ];

  const user = useAppSelector((state) => state.user.user);
  // const currentMenuItem = sidebarNavFlat.find(
  //   ({ url }) => url === location.pathname
  // );

  // const openedSubmenu = sidebarNavigation.find(({ children }) =>
  //   children?.some(({ url }) => url === location.pathname)
  // );

  const [mastersMenuOpen, setMastersMenuOpen] = useState(false);
  const [activeChildMenuIndex, setActiveChildMenuIndex] = useState<
    number | null
  >(11);
  const [activeMenuItem, setActiveMenuItem] = useState(
    user?.role === "client" ? "/client-dashboard" : "/"
  );

  const [maintenanceIndex, setMaintenanceIndex] = useState<number>(
    user?.role === "vendor" ? 11 : 12
  );

  const handleMenu = (index: number) => {
    if (activeChildMenuIndex === index) {
      // If the clicked child menu is already open, set the activeChildMenuIndex to null to close it
      setActiveChildMenuIndex(null);
    } else {
      // If a different child menu is open, set the activeChildMenuIndex to the clicked child menu index
      setActiveChildMenuIndex(index);
    }
  };

  const verticalExampleRef = useRef<HTMLElement | null>(null);
  const horizontalExampleRef = useRef<HTMLElement | null>(null);
  const horizVertExampleRef = useRef<HTMLElement | null>(null);

  const verticalExampleScrollbarRef = useRef<PerfectScrollbar | null>(null);
  const horizontalExampleScrollbarRef = useRef<PerfectScrollbar | null>(null);
  const horizVertExampleScrollbarRef = useRef<PerfectScrollbar | null>(null);

  useEffect(() => {
    setActiveMenuItem(location.pathname);
    // if (location.pathname === "/bus-master" || "/manufacturer-master") {
    if (masterUrl.includes(location.pathname)) {
      setMastersMenuOpen(true);
    } else {
      setMastersMenuOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (verticalExampleRef.current) {
      verticalExampleScrollbarRef.current = new PerfectScrollbar(
        verticalExampleRef.current,
        {
          wheelPropagation: false,
        }
      );
    }

    if (horizontalExampleRef.current) {
      horizontalExampleScrollbarRef.current = new PerfectScrollbar(
        horizontalExampleRef.current,
        {
          wheelPropagation: false,
          suppressScrollY: true,
        }
      );
    }

    if (horizVertExampleRef.current) {
      horizVertExampleScrollbarRef.current = new PerfectScrollbar(
        horizVertExampleRef.current,
        {
          wheelPropagation: false,
        }
      );
    }

    return () => {
      if (verticalExampleScrollbarRef.current) {
        verticalExampleScrollbarRef.current.destroy();
      }
      if (horizontalExampleScrollbarRef.current) {
        horizontalExampleScrollbarRef.current.destroy();
      }
      if (horizVertExampleScrollbarRef.current) {
        horizVertExampleScrollbarRef.current.destroy();
      }
    };
  }, []);

  const renderMenuItems = (sidebarNavigation: SidebarNavigationItem[]) => {
    return sidebarNavigation
      .map((item, index) => {
        if (item.children) {
          if (
            !permissionValidate(item.permissions, user?.permissions as string[])
          ) {
            return null;
          }

          const isOpen = activeChildMenuIndex === index;

          return (
            <li className={`menu-item ${isOpen ? "open" : ""}`} key={item.key}>
              <a
                href="javascript:void(0)"
                className="menu-link menu-toggle"
                onClick={() => {
                  handleMenu(index);
                }}
              >
                <i className={`menu-icon tf-icons bx ${item.icon}`} />
                <div data-i18n="Layouts">{item.title}</div>
              </a>
              <ul className="menu-sub">{renderMenuItems(item.children)}</ul>
            </li>
          );
        } else {
          if (
            !permissionValidate(item.permissions, user?.permissions as string[])
          ) {
            return null;
          }

          if (index === maintenanceIndex) {
            return (
              <>
                <li
                  className={`menu-item ${
                    activeMenuItem === item.url ? "active" : ""
                  }`}
                  key={item.key}
                >
                  <Link
                    to={item.url as string}
                    className="menu-link"
                    onClick={() => setActiveMenuItem(item.url as string)}
                  >
                    <i className={`menu-icon tf-icons bx ${item.icon}`} />
                    <div data-i18n="Analytics">{item.title}</div>
                  </Link>
                </li>
                <div className="col-md-12 mb-4">
                  <div className="divider text-start">
                    <div className="divider-text" />
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <li
                className={`menu-item ${
                  activeMenuItem === item.url ? "active" : ""
                }`}
                key={item.key}
              >
                <Link
                  to={item.url as string}
                  className="menu-link"
                  onClick={() => setActiveMenuItem(item.url as string)}
                >
                  <i className={`menu-icon tf-icons bx ${item.icon}`} />
                  <div data-i18n="Analytics">{item.title}</div>
                </Link>
              </li>
            );
          }
        }
      })
      .filter(Boolean);
  };

  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <Link
            to="/"
            className="app-brand-link"
            onClick={() => setActiveMenuItem("Dashboard")}
          >
            <span className="app-brand-logo demo">
              <img src="./assets/img/icons/brands/cng-icon.svg" width="42px" />
            </span>
            <span
              className="app-brand-text demo menu-text fw-bolder ms-2"
              style={{ textTransform: "uppercase" }}
            >
              CRFM
            </span>
          </Link>
          <a
            href="#"
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle" />
          </a>
        </div>
        <div className="menu-inner-shadow" />
        <ul
          className="menu-inner sidebar-menu py-1"
          ref={verticalExampleRef as any}
        >
          {renderMenuItems(sidebarNavigation)}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
