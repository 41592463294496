import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { notificationController } from "../../../utills/toastNotification";
import { Field } from "formik";
import issueStatusService, {
  IIssueStatus,
} from "../../../services/IssueStatusService";
import { handleAxiosError } from "../../../utills/helper";
import { Roles } from "../../../interfaces/role.interface";
import { useAppSelector } from "../../../hooks/reduxHooks";
import IssueService from "../../../services/IssueService";
interface IIssue {
  _id: string;

  bus: {
    _id: string;
    registerationNumber: string;
  };
  division: {
    _id: string;
    name: string;
  };
  depot: {
    _id: string;
    name: string;
  };
  issueStatus: {
    _id: string;
    name: string;
  };
  issueDescription: string;
  issueDate: string;
  remarks?: string;
}
interface IIssueHistory {
  _id: string;
  busId: BusId;
  issueId: string;
  history: History[];
  createdAt: string;
  updatedAt: string;
}
interface BusId {
  _id: string;
  registerationNumber: string;
}
interface History {
  status: string;
  remark: string;
  type: string;
  _id: string;
  createdAt: string;
}
interface IBusId {
  preCheckId: string;
  busId: string;
  issueId: string;
}

const EditIssue: React.FC = () => {
  const [issueHistory, setPartsAwaitedHistory] = useState<IIssueHistory>();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const user = useAppSelector((state: any) => state.user.user);
  const location = useLocation();
  const state = location.state as IBusId;
  const _id = location.state?.issueId;
  const navigate = useNavigate();

  const formData: IIssue[] = [
    {
      _id: "",
      bus: {
        _id: "",
        registerationNumber: "",
      },
      division: {
        _id: "",
        name: "",
      },
      depot: {
        _id: "",
        name: "",
      },
      issueStatus: {
        _id: "",
        name: "",
      },
      issueDescription: "",
      issueDate: "",
      remarks: "",
    },
  ];

  const [issue, setIssue] = useState<IIssue[]>(formData);
  const [issueStatusList, setIssueStatusList] = useState([]);
  const modalRef = useRef<HTMLDivElement>(null);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");

  const [initialValues, setInitialValues] = useState<Record<string, string>>(
    {}
  );

  const [issueStatusError, setIssueStatusError] = useState<string>("");

  const getIssueHistory = async () => {
    try {
      const { data } = await IssueService.getIssueHistory(state?.issueId);
      setPartsAwaitedHistory(data);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const handleRemark = async (type: "SUBMITTED") => {
    try {
      // Validate the comment
      if (!comment.trim()) {
        setCommentError("Comment is required");
        return;
      }
      // Clear any previous validation error
      setCommentError("");
      await IssueService.addIssueComment(state.issueId, {
        remark: comment,
        status: type,
        type: user?.role.toUpperCase() as string,
      });
      if (type === "SUBMITTED") {
        notificationController.success("Comment added successfully");
        if (modalRef.current) {
          const closeButton = modalRef.current.querySelector(
            ".btn-close"
          ) as HTMLButtonElement;
          closeButton?.click();
        }
      } else {
        notificationController.success("Comments added successfully");
      }
      setComment(""); // Clear the comment after successful submission
      await getIssueHistory();

      setInitialValues({});
    } catch (err) {
      handleAxiosError(err);
    }
    fetchIssue(); // will refresh status when comment is added
  };

  // useEffect(() => {
  const getIssueStatusData = async () => {
    try {
      const { data } = await issueStatusService.getAllIssueStatuses();

      // If the user's role is 'vendor', filter the issueStatusList to show only 'IN PROCESS'
      if (user?.role === Roles.VENDOR) {
        const filteredIssueStatusList = data.map((status: IIssueStatus) => {
          return {
            ...status,
            disabled: status.name !== "IN PROCESS",
          };
        });
        setIssueStatusList(filteredIssueStatusList);
      } else {
        // If the user's role is not 'vendor', set the issueStatusList as it is
        setIssueStatusList(data);
      }
    } catch (err) {
      handleAxiosError(err);
    }
  };

  //   getIssueStatusData();
  // }, [user]);

  // useEffect(() => {
  const fetchIssue = async () => {
    try {
      const response = await axios.get(`/issues/${_id}`);
      setIssue(response.data);
    } catch (error) {
      console.error("Error fetching issue data:", error);
    }
  };
  //   fetchIssue();
  // }, [_id]);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    setIssue((prevIssue) => ({
      ...prevIssue,
      [name]: value,
      0: {
        ...prevIssue[0],
        [name]: value,
      },
    }));
    // Validate the 'Select Issue Status' dropdown
    if (name === "issueStatus" && !value) {
      setIssueStatusError("Please select an issue status");
    } else {
      setIssueStatusError("");
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await axios.put(`/issues/${_id}`, issue);
      setTimeout(() => {
        navigate("/issue-master");
      }, 2000);
      notificationController.success("Issue updated successfully");
    } catch (error) {
      console.error("Error updating issue:", error);
      notificationController.error("Failed to update issue");
    }
  };

  useEffect(() => {
    getIssueHistory();
    getIssueStatusData();
    fetchIssue();
  }, []);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="card">
          <form onSubmit={handleFormSubmit}>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "rgba(105, 108, 255, 0.16)",
                    }}
                  >
                    <th className="col-sm-6">Edit Issue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Issue Date</td>
                    <td>
                      <div className="col-sm-9">
                        <input
                          className="form-control form-control-lg"
                          // placeholder="Enter issue details"
                          name="issueDate"
                          value={issue[0]?.issueDate.split("T")[0]}
                          // onChange={(event) => handleInputChange(event)}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Division</td>
                    <td>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          //   placeholder="Enter Issue No"
                          name="division"
                          value={issue[0]?.division?.name}
                          //   onChange={(event) => handleInputChange(event)}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Depot</td>
                    <td>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          //   placeholder="Enter Issue No"
                          name="depot"
                          value={issue[0]?.depot.name}
                          //   onChange={(event) => handleInputChange(event)}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Bus No.</td>
                    <td>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          //   placeholder="Enter Issue No"
                          name="bus_no"
                          value={issue[0]?.bus.registerationNumber}
                          //   onChange={(event) => handleInputChange(event)}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Issue Description</td>
                    <td>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control form-control-lg"
                          placeholder="Enter issue details"
                          name="issueDescription"
                          value={issue[0]?.issueDescription}
                          onChange={(event) => handleInputChange(event)}
                          style={{
                            width: "400px",
                            height: "auto",
                            minHeight: "100px",
                          }}
                          disabled
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Issue Status</td>
                    <td>
                      <div className="col-sm-9">
                        <select
                          name="issueStatus"
                          className={`form-select ${
                            issueStatusError ? "is-invalid" : ""
                          }`}
                          id="issueStatus"
                          aria-label="Default select example"
                          value={issue[0]?.issueStatus?._id}
                          onChange={(event) => handleInputChange(event)}
                          disabled={issue[0]?.issueStatus?.name === "RESOLVED"}
                        >
                          <option value="">Select Issue Status</option>
                          {issueStatusList.map((status: any) => (
                            <option
                              key={status._id}
                              value={status._id}
                              disabled={status.disabled}
                              style={{
                                backgroundColor: status.disabled
                                  ? "#CCCCCC"
                                  : "inherit",
                              }}
                            >
                              {status.name}
                            </option>
                          ))}
                        </select>
                        {issueStatusError && (
                          <div className="invalid-feedback">
                            {issueStatusError}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="demo-inline-spacing d-flex justify-content-center"
              style={{ marginBottom: "10px", gap: "30px" }}
            >
              {issue[0]?.issueStatus?.name !== "RESOLVED" && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/issue-master");
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
        <div className="card mt-5">
          <div className="table-responsive text-nowrap">
            <table className="table mt-2">
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgba(105, 108, 255, 0.16)",
                  }}
                >
                  <th className="col-sm-6">Comment Section for Issue</th>
                </tr>
              </thead>
              <tbody>
                {issueHistory && issueHistory.history.length > 0 ? (
                  issueHistory.history.map((item, index) => (
                    <tr key={index}>
                      <td colSpan={2}>
                        <div
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: `${
                              item.type === "CLIENT" ? "start" : "end"
                            }`,
                          }}
                        >
                          <b>{item.type === "CLIENT" ? "CLIENT" : item.type}</b>
                          &nbsp;&nbsp;
                          <p
                            style={{
                              whiteSpace: "normal",
                              maxWidth: "300px",
                            }}
                          >
                            {item.remark}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No comments available</td>
                  </tr>
                )}
                {user && issue[0]?.issueStatus?.name !== "RESOLVED" && (
                  <tr>
                    <td>Add Comment</td>
                    <td>
                      <div className="col-sm-9">
                        <textarea
                          id="basic-default-message"
                          className={`form-control ${
                            commentError ? "is-invalid" : ""
                          }`}
                          placeholder="Add Comment"
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                            setCommentError(""); // Clear the validation error on input change
                          }}
                          disabled={issue[0]?.issueStatus?.name === "RESOLVED"}
                        />
                        {commentError && (
                          <div className="invalid-feedback comment-error">
                            {commentError}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="demo-inline-spacing d-flex justify-content-center"
            style={{ marginBottom: "10px", gap: "30px" }}
          >
            <Link to="/issue-master">
              <button type="button" className="btn btn-primary">
                Back
              </button>
            </Link>
            {user && issue[0]?.issueStatus?.name !== "RESOLVED" && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleRemark("SUBMITTED")}
                >
                  Comment
                </button>
              </>
            )}
          </div>
        </div>
        {/*issue chat end*/}
      </div>
    </div>
  );
};

export default EditIssue;
