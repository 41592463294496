import React, { useEffect, useState } from "react";
import dashboardService from "../../services/DashboardService";
import { BusData, IDashboardList } from "../../interfaces/dashboard.interface";
import dayjs from "dayjs";
import { getStatusColorCode, handleAxiosError, permissionValidate } from "../../utills/helper";
import { useNavigate } from "react-router-dom";
import { BusStatusRedirection } from "../../utills/permissions";
import { useAppSelector } from "../../hooks/reduxHooks";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);

  const [page, setPage] = useState<number>(1);

  const [sortColumn, setSortColumn] = useState<keyof BusData>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">();

  const handleSort = (column: keyof BusData) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (column: string) => {
    if (column === sortColumn) {
      return sortOrder === "asc" ? "bx-chevrons-up" : "bx-chevrons-down";
    }
    return "bx-chevrons-down";
  };

  const pointerStyle = [
    "RECEIVED",
    "WIP PARTS AWAITED",
    "HANDED OVER",
    "WORK IN PROGRESS",
    "PAYMENT RECEIVED",
    "INVOICED",
    "COMPLETED",
  ];

  const selectPageHandler = (selectedPage: number) => {
    if (
      dashboardData &&
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(dashboardData?.busData.length / 10) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const [dashboardData, setDashboardData] = useState<IDashboardList>({
    busData: [],
    statusCounts: {
      RECEIVED: 0,
      "WIP PARTS AWAITED": 0,
      "WORK IN PROGRESS": 0,
      COMPLETED: 0,
      "PAYMENT RECEIVED": 0,
      "PRE-CHECK": 0,
      "HANDED OVER": 0,
      INVOICED: 0,
      INITIATED: 0,
      "RECEIVED BACK": 0,
    },
  });

  async function getDashboardDetails() {
    try {
      const { data } = await dashboardService.getDashboardData();
      setDashboardData(data);
    } catch (err) {
      handleAxiosError(err);
    }
  }

  const handleRedirect = (status: string, item: BusData) => {
    switch (status) {
      case "WIP PARTS AWAITED":
        navigate("/parts-awaited", {
          state: {
            preCheckId: item._id,
            busId: item._id,
          },
        });
        break;
      case "RECEIVED":
        navigate("/view-bus-master", {
          state: {
            bus_id: item._id,
          },
        });
        break;
      case "WORK IN PROGRESS":
        navigate("/wip");
        break;
      case "PAYMENT RECEIVED":
        navigate("/payment-received");
        break;
      case "HANDED OVER":
        navigate("/view-hand-over", {
          state: {
            handover_id: item._id,
            registerationNumber: item.registerationNumber,
          },
        });
        break;
      case "INVOICED":
        navigate("/generate-invoice");
        break;
      case "COMPLETED":
        navigate("/completed");
        break;
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useEffect(() => {
    setDashboardData({
      ...dashboardData,
      busData: dashboardData.busData.sort((a, b) => {
        if (sortColumn) {
          const aValue = getNestedPropertyValue(a, sortColumn);
          const bValue = getNestedPropertyValue(b, sortColumn);
          if (sortOrder === "asc") {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          } else {
            if (aValue > bValue) return -1;
            if (aValue < bValue) return 1;
            return 0;
          }
        } else {
          return 0;
        }
      }),
    });
  }, [sortOrder, sortColumn]);

  const getNestedPropertyValue = (obj: any, propertyPath: string) => {
    const properties = propertyPath.split(".");
    return properties.reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  return (
    <>
      <div className="container-xxl flex-grow-1 pt-4">
        <div className="card-container">
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#d5bd2f",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/bus-master")}
                >
                  {(dashboardData?.statusCounts &&
                    Object.values(dashboardData?.statusCounts).reduce(
                      (acc, curr) => acc + curr,
                      0
                    )) ||
                    0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/bus-master")}
                >
                  TOTAL BUS
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#71dd37",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/bus-master")}
                >
                  {dashboardData?.statusCounts?.RECEIVED || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/bus-master")}
                >
                  RECEIVED
                </span>
              </div>
            </div>
          </div>

          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#e400ff",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/wip-parts-awaited")}
                >
                  {dashboardData?.statusCounts?.["WIP PARTS AWAITED"] || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/wip-parts-awaited")}
                >
                  WIP PARTS AWAITED
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#d5bd2f",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/wip")}
                >
                  {dashboardData?.statusCounts?.["WORK IN PROGRESS"] || 0}
                </h3>
                <span className="cardHeight cardAlign" onClick={() => navigate("/wip")}>
                  WORK IN PROGRESS
                </span>
              </div>
            </div>
          </div>

          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{ color: "#71dd37", fontSize: "2rem" }}
                  onClick={() => navigate("/completed")}
                >
                  {dashboardData?.statusCounts?.COMPLETED || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/completed")}
                >
                  COMPLETED
                </span>
              </div>
            </div>
          </div>

          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#697a8d",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/handed-over")}
                >
                  {dashboardData?.statusCounts?.["HANDED OVER"] || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/handed-over")}
                >
                  HANDED OVER
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#d5bd2f",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/generate-invoice")}
                >
                  {dashboardData?.statusCounts?.["HANDED OVER"] || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/generate-invoice")}
                >
                  PENDING INVOICE
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#03c3ec",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/unpaid-status")}
                >
                  {dashboardData?.statusCounts?.["INVOICED"] || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/unpaid-status")}
                >
                  UNPAID PAYMENT
                </span>
              </div>
            </div>
          </div>
          <div className="mb-4 card-sub-container">
            <div className="card">
              <div className="card-body">
                <h3
                  className="card-title text-nowrap mb-1"
                  style={{
                    color: "#ffab00",
                    fontSize: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/payment-received")}
                >
                  {dashboardData?.statusCounts?.["PAYMENT RECEIVED"] || 0}
                </h3>
                <span
                  className="cardHeight cardAlign"
                  onClick={() => navigate("/payment-received")}
                >
                  PAYMENT RECEIVED
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl flex-grow-1">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="card">
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr
                        className="text-nowrap"
                        style={{ backgroundColor: "rgba(105, 108, 255, 0.16)" }}
                      >
                        <th onClick={() => handleSort("registerationNumber")}>
                          Bus No.{" "}
                          <i
                            className={`tf-icon bx ${getSortIcon(
                              "registerationNumber"
                            )}`}
                          />
                        </th>
                        <th
                          onClick={() =>
                            handleSort("currentStatus.name" as keyof BusData)
                          }
                        >
                          Stage{" "}
                          <i
                            className={`tf-icon bx ${getSortIcon(
                              "currentStatus.name"
                            )}`}
                          />
                        </th>
                        <th
                          onClick={() =>
                            handleSort("depotData.name" as keyof BusData)
                          }
                        >
                          Depot{" "}
                          <i
                            className={`tf-icon bx ${getSortIcon(
                              "depotData.name"
                            )}`}
                          />
                        </th>
                        <th
                          onClick={() =>
                            handleSort("statusData.createdAt" as keyof BusData)
                          }
                        >
                          Date{" "}
                          <i
                            className={`tf-icon bx ${getSortIcon(
                              "statusData.createdAt"
                            )}`}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.busData &&
                        dashboardData?.busData.length > 0 &&
                        dashboardData?.busData
                          .slice(page * 10 - 10, page * 10)
                          .map((bus) => (
                            <tr key={bus._id}>
                              <td>{bus?.registerationNumber}</td>
                              <td>
                                <span
                                  className={`badge ${getStatusColorCode(
                                    bus?.currentStatus?.name
                                  )} me-1}
                              ${(permissionValidate(
                                [BusStatusRedirection.BUS_STATUS_REDIRECTION],
                                user?.permissions as string[]
                               ) && pointerStyle.includes(bus?.currentStatus?.name))
                                      ? "linkPointer"
                                      : "spanDisable"
                                    }`}
                                  onClick={() =>
                                    handleRedirect(bus?.currentStatus?.name, bus)
                                  }
                                >
                                  {bus?.currentStatus?.name}
                                </span>
                              </td>
                              <td>{bus?.depotData?.name}</td>
                              <td>
                                <div className="col-sm-9">
                                  {dayjs(bus?.statusData?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>

                  {dashboardData && dashboardData.busData.length <= 0 && (
                    <h4
                      className="data-found"
                      style={{
                        margin: "0 auto",
                        textAlign: "center",
                        fontWeight: "700",
                        paddingTop: "10px",
                      }}
                    >
                      No Records Found
                    </h4>
                  )}

                  <br />
                  {dashboardData && dashboardData?.busData.length > 10 && (
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li className="page-item prev">
                          <a className="page-link" href="javascript:void(0);">
                            <i
                              className={`tf-icon bx bx-chevrons-left ${page > 1 ? "" : "disabled"
                                }`}
                              onClick={() => selectPageHandler(page - 1)}
                            // className={page > 1 ? "" : "pagination__disable"}
                            ></i>
                          </a>
                        </li>
                        {[
                          ...Array(Math.ceil(dashboardData.busData.length / 10)),
                        ].map((_, i) => {
                          if (
                            i === 0 ||
                            i ===
                            Math.ceil(dashboardData.busData.length / 10) - 1 ||
                            (i >= page - 2 && i <= page + 2)
                          ) {
                            return (
                              <li
                                className={`page-item ${page === i + 1 ? "active" : ""
                                  }`}
                                key={i}
                              >
                                <a
                                  className="page-link"
                                  href="javascript:void(0);"
                                  onClick={() => selectPageHandler(i + 1)}
                                >
                                  {i + 1}
                                </a>
                              </li>
                            );
                          } else if (i === page - 3 || i === page + 3) {
                            return (
                              <li className="page-item disabled" key={i}>
                                <a
                                  className="page-link"
                                  href="javascript:void(0);"
                                >
                                  ...
                                </a>
                              </li>
                            );
                          }
                        })}
                        <li className="page-item next">
                          <a className="page-link" href="javascript:void(0);">
                            <i
                              className={`tf-icon bx bx-chevrons-right ${page < dashboardData?.busData.length / 10
                                ? ""
                                : "disabled"
                                }`}
                              onClick={() => selectPageHandler(page + 1)}
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
