import axios from "axios";

export interface ILoginForm {
  email: string;
  password: string;
}

const authService = {
  login: (data: ILoginForm) => {
    return axios.post("/users/login", data);
  },
};

export default authService;
