import axios from "axios";

export interface IDepotList {
  _id: string;
  name: string;
  address: string;
  division: any;
}

const depotService = {
  getDepotList: async (): Promise<{ data: IDepotList[] }> => {
    const result = await axios.get("/master/depot?maintenance=true");
    return result.data;
  },

  getUserDepotList: async (
    divisionId: any
  ): Promise<{ data: IDepotList[] }> => {
    const result = await axios.get(
      `/master/depot?maintenance=true&&divisionId=${divisionId}`
    );
    return result.data;
  },

  getDepotDependentBusList: async (
    depotId: string
  ): Promise<{ data: { registerationNumber: string; _id: string }[] }> => {
    const result = await axios.get(`/bus/depot/${depotId}`);
    return result.data;
  },

  addDepot: async (data: { name: string; address: string }) => {
    return await axios.post("/master/depot", data);
  },

  getDepotById: async (depotId: string): Promise<{ data: IDepotList }> => {
    const response = await axios.get(`/master/depot/${depotId}`);
    return response.data;
  },

  updatedepotById: async (
    depotId: string,
    data: { name: string; address: string }
  ) => {
    return await axios.put(`/master/depot/${depotId}`, data);
  },

  deleteDepot: async (depotId: string) => {
    return await axios.delete("/master/depot", {
      data: {
        _ids: [depotId],
      },
    });
  },
};

export default depotService;
