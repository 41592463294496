import axios from "axios";
import {
  IHandOverPartsAddedFormData,
  IHandOverRequest,
} from "../interfaces/handover.interface";

export interface IRemovedParts {
  _id: string;
  name: string;
  isVerified: boolean;
}

export interface IHandoverDocument {
  _id: string;
  busId: string;
  type: string;
  path: string;
  docUploadRemark: string;
  createdAt: string;
  updatedAt: string;
}

const handOverService = {
  getHandOverFormDetails: async (
    busId: string
  ): Promise<{ data: IHandOverPartsAddedFormData }> => {
    const response = await axios.get(`/handover/handoverformdata/${busId}`);
    return response.data;
  },

  getHandoverDetails: (handover_id: string) => {
    return axios.get(`/handover/${handover_id}`);
  },

  addHandOverRemark: (busId: string, data: any) => {
    return axios.post(`/handover/addremark/${busId}`, data);
  },

  getHandOverRemovedParts: async (): Promise<{ data: IRemovedParts[] }> => {
    const response = await axios.get("/handover/removedpartshandoverformdata");
    return response.data;
  },

  addHandOverDetails: (data: IHandOverRequest) => {
    return axios.post("/handover", data);
  },

  getHandoverDocument: async (data: { busId: string; type: string }) => {
    return await axios.post("/upload/getdocuments", data);
  },
};

export default handOverService;
