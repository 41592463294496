import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import userService from "../../../services/UserService";
import { handleAxiosError } from "../../../utills/helper";
import { notificationController } from "../../../utills/toastNotification";
import _ from "lodash";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [confirmPasswordShow, setConfirmPasswordShow] =
    useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handleResetPassword = async (passward: string): Promise<Boolean> => {
    try {
      const { data } = await userService.resetPassword({
        newPassword: passward,
        token: token as string,
      });
      return true;
    } catch (error) {
      handleAxiosError(error);
      return false;
    }
  };

  const verifyPasswordToken = async (token: string) => {
    try {
      const { data } = await userService.forgotPasswordVerifyLink(token);
    } catch (error) {
      navigate("/login");
      handleAxiosError(error);
    }
  };

  const handlePasswordIcon = () => {
    if (passwordShow) {
      setPasswordShow(false);
    } else {
      setPasswordShow(true);
    }
  };

  const handleConfirmPasswordIcon = () => {
    if (confirmPasswordShow) {
      setConfirmPasswordShow(false);
    } else {
      setConfirmPasswordShow(true);
    }
  };

  useEffect(() => {
    if (_.isEmpty(token)) {
      navigate("/login");
    } else {
      verifyPasswordToken(token as string);
    }
  }, []);

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper login-page authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* Register */}
          <div className="card">
            <div className="card-body">
              {/* Logo */}
              <div className="app-brand justify-content-center">
                <a href="javascript:void(0);" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <img
                      src="./assets/img/icons/brands/cng-icon.svg"
                      width="42px"
                    />
                  </span>
                  <span
                    className="app-brand-text demo text-body fw-bolder"
                    style={{ textTransform: "uppercase" }}
                  >
                    CRFM
                  </span>
                </a>
              </div>
              {/* /Logo */}
              <h4 className="mb-2">Reset Password</h4>
              <p className="mb-4">
                Please create a new password that you don't use on any other
                site
              </p>
              <Formik
                initialValues={{
                  newPassword: "",
                  passwordConfirmation: "",
                }}
                validateOnChange={true}
                validationSchema={yup.object().shape({
                  newPassword: yup
                    .string()
                    .required("Password Required")
                    .matches(
                      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[0-9A-Za-z!@#$%^&*]{8,16}$/g,
                      "Password should contain at least 1 Upper Case, 1 Lower Case, 1 Number, 1 Special Character (! @ # $ % ^ & *), Min length 8 and Max length 16"
                    ),
                  passwordConfirmation: yup
                    .string()
                    .oneOf([yup.ref("newPassword")], "Password does not match"),
                })}
                onSubmit={async (values) => {
                  const result = await handleResetPassword(values.newPassword);
                  if (result) {
                    notificationController.success(
                      "Password Reset Successfully"
                    );
                    setTimeout(() => {
                      navigate("/login");
                    }, 2500);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">
                          New Password
                        </label>
                      </div>
                      <div className="input-group input-group-merge">
                        <Field
                          type={passwordShow ? "text" : "password"}
                          id="newPassword"
                          className="form-control"
                          name="newPassword"
                          placeholder="············"
                          aria-describedby="password"
                          onChange={handleChange}
                        />

                        <span className="input-group-text cursor-pointer">
                          <i
                            className={
                              passwordShow ? "bx bx-show" : "bx bx-hide"
                            }
                            onClick={handlePasswordIcon}
                          />
                        </span>
                      </div>
                      {errors.newPassword && touched.newPassword ? (
                        <div style={{ color: "red", marginTop: "2px" }}>
                          <ol className="text-start">
                            {errors.newPassword
                              .split(",")
                              .map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                          </ol>
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" htmlFor="password">
                          Confirm Password
                        </label>
                      </div>
                      <div className="input-group input-group-merge">
                        <Field
                          type={confirmPasswordShow ? "text" : "password"}
                          id="passwordConfirmation"
                          className="form-control"
                          name="passwordConfirmation"
                          placeholder="············"
                          aria-describedby="password"
                          onChange={handleChange}
                        />

                        <span className="input-group-text cursor-pointer">
                          <i
                            className={
                              confirmPasswordShow ? "bx bx-show" : "bx bx-hide"
                            }
                            onClick={handleConfirmPasswordIcon}
                          />
                        </span>
                      </div>
                      {errors["passwordConfirmation"] &&
                      touched["passwordConfirmation"] ? (
                        <div style={{ color: "red", marginTop: "2px" }}>
                          {errors["passwordConfirmation"]}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="remember-me"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="remember-me"
                        >
                          {" "}
                          Remember Me{" "}
                        </label>
                      </div>
                    </div> */}
                    <div className="mb-3">
                      <a href="javascript:void(0);">
                        <button
                          className="btn btn-primary d-grid w-100"
                          type="submit"
                          // disabled={submitDisabled}
                        >
                          Reset Password
                        </button>
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <p className="text-center">
                <span>New on our platform?</span>
                <a href="javascript:void(0);">
                  <span>Create an account</span>
                </a>
              </p> */}
            </div>
          </div>
          {/* /Register */}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
