import axios from "axios";
export interface IAddIssue {
  bus: string;
  depot?: string;
  division?: any;
  issueStatus: string;
  issueDescription: string;
  issueDate: string;
}

export interface IIssueComment {
  status: "SUBMITTED";
  remark: string;
  type: string;
}

export interface IIssueHistory {
  _id: string;
  busId: BusId;
  issueId: string;
  history: History[];
  createdAt: string;
  updatedAt: string;
}

export interface BusId {
  _id: string;
  registerationNumber: string;
}

export interface History {
  status: string;
  remark: string;
  type: string;
  _id: string;
  createdAt: string;
}

const IssueService = {
  addIssue: async (data: IAddIssue) => {
    return await axios.post("/issues", data);
  },

  viewIssue: async () => {
    return await axios.get("/issues");
  },

  addIssueComment: (issueId: string, data: IIssueComment) => {
    return axios.post(`/bus/issue/issuecomments/${issueId}`, data);
  },

  getIssueHistory: async (
    issueId: string
  ): Promise<{ data: IIssueHistory }> => {
    const response = await axios.get(`/bus/issue/issuehistory/${issueId}`);
    return response.data;
  },
};

export default IssueService;
