import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ILoginResponse, Status } from "../../interfaces/interfaces";
import authService, { ILoginForm } from "../../services/AuthService";
import { AxiosError } from "axios";
import {
  deletePersistUser,
  deleteToken,
  persistToken,
} from "../../services/LocalStorageService";
import { fetchUserById, setUser } from "./userSlice";

export interface AuthSlice {
  token: string;
  status: Status;
  redirectUrl: string;
}

const initialState: AuthSlice = {
  token: "",
  status: Status.IDLE,
  redirectUrl: "/",
};

export const doLogin = createAsyncThunk<
  ILoginResponse,
  ILoginForm,
  {
    rejectValue: AxiosError;
  }
>(
  "auth/login",
  async (
    loginPayload: ILoginForm,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const res = await authService.login(loginPayload);
      const data: ILoginResponse = res.data;
      persistToken(data.data.token);
      dispatch(
        fetchUserById(data.data.userId, data.data.role, data.data.permissions)
      );
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  }
);

export const doLogout = createAsyncThunk(
  "auth/doLogout",
  (payload, { dispatch }) => {
    deleteToken();
    deletePersistUser();
    dispatch(setUser(null));
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.pending, (state) => {
      state.status = Status.LOADING;
    });
    builder.addCase(
      doLogin.fulfilled,
      (state, action: PayloadAction<ILoginResponse>) => {
        state.token = action.payload.data.token;
        state.status = Status.IDLE;
        state.redirectUrl = action.payload.data.redirectUrl;
      }
    );
    builder
      .addCase(doLogin.rejected, (state) => {
        state.status = Status.ERROR;
      })
      .addCase(doLogout.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(doLogout.fulfilled, (state) => {
        state.token = "";
        state.status = Status.IDLE;
        state.redirectUrl = "";
      })
      .addCase(doLogout.rejected, (state) => {
        state.status = Status.ERROR;
      });
  },
});

export default authSlice.reducer;
