import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const MainLayout: React.FC = () => {
  const [menuExpand, setMenuExpand] = useState<boolean>(false);

  const handleMenuExpand = () => {
    if (menuExpand) {
      setMenuExpand(false);
    } else {
      setMenuExpand(true);
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div
        className={`layout-container ${menuExpand ? "layout-menu-expanded" : ""
          }`}
        onClick={handleMenuExpand}
      >
        <Sidebar />
        <div className="layout-page">
          <Navbar />
          <div className="content-wrapper">
            <Outlet />
            <footer className="content-footer footer bg-footer-theme">
              <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">
                  ©{new Date().getFullYear()}
                  <a
                    href="https://www.stspl.com/"
                    target="_blank"
                    className="footer-link fw-bolder"
                    style={{marginLeft: '7px'}}
                  >
                  Synergy Technology Services PVT LTD.
                </a>
              </div>
          </div>
        </footer>
        {/* / Footer */}
        <div className="content-backdrop fade" />
      </div>
      {/* Content wrapper */}
    </div>
        {/* / Layout page */ }
      </div >
  {/* Overlay */ }
  < div className = "layout-overlay layout-menu-toggle" />
    </div >
  );
};

export default MainLayout;
