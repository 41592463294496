import { AxiosError } from "axios";
import { notificationController } from "./toastNotification";

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getStatusColorCode(status: string) {
  switch (status) {
    case "RECEIVED":
      return "bg-label-primary";
    case "WIP PARTS AWAITED":
      return "bg-label-custom-four";
    case "WORK IN PROGRESS":
      return "bg-label-custom-two";
    case "COMPLETED":
      return "bg-label-success";
    case "PAYMENT RECEIVED":
      return "bg-label-warning";
    case "PRE-CHECK":
      return "bg-label-custom-one";
    case "HANDED OVER":
      return "bg-label-custom-three";
    case "INVOICED":
      return "bg-label-info";
    case "INITIATED":
      return "bg-label-primary";
    case "RECEIVED BACK":
      return "bg-label-primary";
    default:
      return "bg-label-primary";
  }
}

export function handleAxiosError(err: any): boolean {
  const error = err as AxiosError<any>;
  notificationController.error(error.response?.data?.message);
  return false;
}

export const permissionValidate = (
  allowPermission: string[],
  userPermission: string[]
) => {
  const validate = userPermission?.filter((item) => {
    return allowPermission.includes(item);
  }) as string[];
  return validate.length > 0 ? true : false;
};

export const matchBusNumber = (busNumber: string): boolean => {
  if (
    /^[A-Za-z]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}[A-Za-z\d]{0,2}$/i.test(
      busNumber
    )
  ) {
    return true;
  } else {
    return false;
  }
};
