import * as yup from "yup";

export const LoginSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required("Email Required")
    .max(30, "Max 30 character allowed")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g,
      "Invalid Email"
    ),
  password: yup
    .string()
    .trim()
    .required("Password Required")
    .matches(/^.{8,20}$/g, "Min 8 and Max 20 character allowed"),
});
