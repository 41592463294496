import axios from "axios";
import { IAddBus } from "../interfaces/interfaces";
import { IListBus } from "../interfaces/bus.interface";

const busService = {
  getBus: () => {
    return axios.get("/bus");
  },
  addBus: (data: IAddBus) => {
    return axios.post("/bus", data);
  },

  checkDuplicateBusNo: (bus_no: string) => {
    return axios.get(`/validation/bus-number/${bus_no}`);
  },

  getFormData: (data: string[]) => {
    return axios.post("/bus/master/formdata", {
      values: data,
    });
  },
  getBusListData: async (): Promise<{ data: IListBus[] }> => {
    const result = await axios.get("/bus");
    return result.data;
  },

  getBusDetails: (busId: string) => {
    return axios.get(`/bus/${busId}`);
  },

  deleteBus: (busId: string) => {
    return axios.delete("/bus", {
      data: {
        _ids: [busId],
      },
    });
  },

  updateBus: (busId: string, data: IAddBus) => {
    return axios.put(`/bus/${busId}`, data);
  },

  getBusPrintData: async (data: {
    busId: string;
    type: string;
  }): Promise<{ data: string }> => {
    const result = await axios.post(`/bus/download/busdata`, data);
    return result.data;
  },
};

export default busService;
