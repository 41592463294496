import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/reduxHooks";
import _ from "lodash";
import { Spinner } from "react-bootstrap";
import { handleAxiosError, matchBusNumber, permissionValidate } from "../../utills/helper";
import Fuse from "fuse.js";
import maintenanceService, {
  IAccidentMaintenanceList,
} from "../../services/MaintenanceService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MaintenancePermission } from "../../utills/permissions";

const AccidentBreakdownList: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [accidentBreakdownList, setAccidentBreakdownList] = useState<
    IAccidentMaintenanceList[]
  >([]);
  const [accidentBreakdownListMemo, setAccidentBreakdownListmemo] = useState<
    IAccidentMaintenanceList[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBus, setSelectedBus] = useState<IAccidentMaintenanceList>();
  const modalRef = useRef<HTMLDivElement>(null);

  const getAccidentBreakdownList = async () => {

    setLoading(true);
    try {
      const { data } = await maintenanceService.getAccidentBreakdownList();

      setAccidentBreakdownList(data);
      setAccidentBreakdownListmemo(data);
      setLoading(false);
    } catch (err) {
      handleAxiosError(err);
      setLoading(false);
    }
  };

  const [page, setPage] = useState<number>(1);

  const selectPageHandler = (selectedPage: number) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(Math.ceil(accidentBreakdownList.length) / 10) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  const renderTooltip = (title: string) => (
    <Tooltip id="tooltip">
      <span>{title}</span>
    </Tooltip>
  );

  const debouncedSearch = _.debounce((search) => {
    // && /^[a-zA-Z0-9\s]+$/.test(search)
    let input = search.replace(/ /g, "");
    if (input && matchBusNumber(input)) {
      const options = {
        shouldSort: true,
        threshold: search.length >= 9 ? 0.1 : 0.2,
        location: 1000,
        distance: 100,
        includeMatches: true,
        minMatchCharLength: 1,
        includeScore: true,
        keys: ["registerationNumber"],
      };
      const fuse = new Fuse(accidentBreakdownListMemo, options);
      const result = fuse.search(input);
      if (result.length > 0) {
        setPage(1);
        setAccidentBreakdownList(result.map((item) => item.item));
      } else {
        setAccidentBreakdownList([]);
      }
    } else {
      setAccidentBreakdownList([]);
    }

    if (!search) {
      setAccidentBreakdownList(accidentBreakdownListMemo);
    }
  }, 300); // Adjust the debounce delay (in milliseconds) as needed

  const handleSearch = (search: string) => {
    debouncedSearch(search);
  };

  const [sortColumn, setSortColumn] =
    useState<keyof IAccidentMaintenanceList>();
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">();

  const handleSort = (column: keyof IAccidentMaintenanceList) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (column: string) => {
    if (column === sortColumn) {
      return sortOrder === "asc" ? "bx-chevrons-up" : "bx-chevrons-down";
    }
    return "bx-chevrons-down";
  };

  useEffect(() => {
    getAccidentBreakdownList();
  }, []);

  useEffect(() => {
    setAccidentBreakdownList((prevAccidentBreakdownList) => {
      const sortedList = [...prevAccidentBreakdownList].sort((a, b) => {
        if (sortColumn) {
          const aValue = getNestedPropertyValue(a, sortColumn);
          const bValue = getNestedPropertyValue(b, sortColumn);
          if (sortOrder === "asc") {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          } else {
            if (aValue > bValue) return -1;
            if (aValue < bValue) return 1;
            return 0;
          }
        } else {
          return 0;
        }
      });

      return sortedList;
    });
  }, [sortOrder, sortColumn]);

  const getNestedPropertyValue = (obj: any, propertyPath: string) => {
    const properties = propertyPath.split(".");
    return properties.reduce((o, p) => (o ? o[p] : undefined), obj);
  };

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <h4 className="fw-bold py-3">
          <span className="text-muted fw-light">Accident / Breakdown</span>
        </h4>
        <div className="col-lg-12 mb-4 order-0">
          <div className="d-flex justify-content-between my-3">
            <div>
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
            <div className="color-indicator-grid">
              {permissionValidate(
                [MaintenancePermission.ADD_ACCIDENT_MAINTENANCE],
                user?.permissions as string[]
              ) ? (
                <div className="section">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/add-accident-maintenance");
                    }}
                  >
                    Accident
                  </button>
                </div>
              ) : (
                null
              )}
              {permissionValidate(
                [MaintenancePermission.ADD_BREAKDOWN_MAINTENANCE],
                user?.permissions as string[]
              ) ? (
                <div className="section">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      navigate("/add-breakdown-maintenance");
                    }}
                  >
                    Breakdown
                  </button>
                </div>
              ) : (
                null
              )}
            </div>
          </div>
          <div className="card">
            <div className="card">
              <div
                className="table-responsive text-nowrap"
                data-bs-ride="Layouts"
              >
                <table className="table">
                  <thead>
                    <tr
                      className="text-nowrap"
                      style={{ backgroundColor: "rgba(105, 108, 255, 0.16" }}
                    >
                      <th
                        onClick={() =>
                          handleSort(
                            "registerationNumber" as keyof IAccidentMaintenanceList
                          )
                        }
                      >
                        Bus No.{" "}
                        <i
                          className={`tf-icon bx ${getSortIcon(
                            "registerationNumber"
                          )}`}
                        />
                      </th>
                      <th onClick={() => handleSort("depot")}>
                        Depot
                        <i
                          className={`tf-icon bx ${getSortIcon("depot.name")}`}
                        />
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      accidentBreakdownList &&
                      accidentBreakdownList.length > 0 &&
                      accidentBreakdownList
                        .slice(page * 10 - 10, page * 10)
                        .map((item) => (
                          <tr key={item._id}>
                            <td>{item?.registerationNumber}</td>
                            <td>{item?.depot}</td>
                            <td>
                              <OverlayTrigger
                                placement="top"
                                overlay={renderTooltip("View")}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  // onClick={() => handleChangeStatus(item._id)}
                                  onClick={() =>
                                    navigate("/view-accident-breakdown", {
                                      state: {
                                        busId: item._id,
                                      },
                                    })
                                  }
                                // data-bs-toggle="modal"
                                // data-bs-target="#exampleModal"
                                >
                                  <span className="tf-icons bx bxs-show"></span>
                                </button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
                {accidentBreakdownList && accidentBreakdownList.length <= 0 && (
                  <h4
                    className="data-found"
                    style={{
                      margin: "0 auto",
                      textAlign: "center",
                      fontWeight: "700",
                      paddingTop: "10px",
                    }}
                  >
                    No Records Found
                  </h4>
                )}
                <br />
                {accidentBreakdownList.length > 10 && (
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item prev">
                        <a className="page-link" href="javascript:void(0);">
                          <i
                            className={`tf-icon bx bx-chevrons-left ${page > 1 ? "" : "disabled"
                              }`}
                            onClick={() => selectPageHandler(page - 1)}
                          ></i>
                        </a>
                      </li>
                      {[
                        ...Array(Math.ceil(accidentBreakdownList.length / 10)),
                      ].map((_, i) => {
                        if (
                          i === 0 ||
                          i ===
                          Math.ceil(accidentBreakdownList.length / 10) - 1 ||
                          (i >= page - 2 && i <= page + 2)
                        ) {
                          return (
                            <li
                              className={`page-item ${page === i + 1 ? "active" : ""
                                }`}
                              key={i}
                            >
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                                onClick={() => selectPageHandler(i + 1)}
                              >
                                {i + 1}
                              </a>
                            </li>
                          );
                        } else if (i === page - 3 || i === page + 3) {
                          return (
                            <li className="page-item disabled" key={i}>
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                              >
                                ...
                              </a>
                            </li>
                          );
                        }
                      })}
                      <li className="page-item next">
                        <a className="page-link" href="javascript:void(0);">
                          <i
                            className={`tf-icon bx bx-chevrons-right ${page < accidentBreakdownList.length / 10
                              ? ""
                              : "disabled"
                              }`}
                            onClick={() => selectPageHandler(page + 1)}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                WIP Complete
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Are you sure want to handover bus -{" "}
              {selectedBus?.registerationNumber}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleChangeStatus(selectedBus?._id as string)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AccidentBreakdownList;
