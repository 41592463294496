import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import MainLayout from "../components/layouts/MainLayout";
import Dashboard from "../components/dashboard/Dashboard";
import { withLoading } from "../hocs/withLoading.hoc";
import { useAppSelector } from "../hooks/reduxHooks";
import RoleBasedAuth from "./RoleBasedAuth";
import { Roles } from "../interfaces/role.interface";
import LoginForm from "../components/auth/LoginForm/LoginForm";
import ForgotPassword from "../components/auth/ForgotPassword/ForgotPassword";
import {
  MaintenancePermission,
  MasterPermission,
  OperationPermission,
  ReportsPermission,
  SidebarPermission,
} from "../utills/permissions";
import ResetPassword from "../components/auth/ResetPassword/ResetPassword";
import SiteMaintenance from "../components/site-maintenance/SiteMaintenance";
import AccidentBreakdownList from "../components/maintenance/AccidentBreakdownList";
import ViewAccidentBreakdown from "../components/maintenance/ViewAccidentBreakdown";
import AddAccidentMaintenance from "../components/maintenance/AddAccidentMaintenance";
import AddBreakdownMaintenance from "../components/maintenance/AddBreakdownMaintenance";
import AddIssue from "../components/master/issueMaster/AddIssue";
import EditIssue from "../components/master/issueMaster/EditIssue";

const ClientDashboard = React.lazy(
  () => import("../components/dashboard/ClientDashboard")
);

const BusMaster = React.lazy(
  () => import("../components/master/busmaster/BusMaster")
);
const AddBus = React.lazy(
  () => import("../components/forms/AddBusForm/AddBus")
);
const EditBus = React.lazy(
  () => import("../components/master/busmaster/EditBus")
);
const ChangeDepot = React.lazy(
  () => import("../components/master/busmaster/ChangeDepot")
);
const ViewBus = React.lazy(
  () => import("../components/master/busmaster/ViewBusMaster")
);
const RaiseIssue = React.lazy(
  () => import("../components/master/issueMaster/RaiseIssue")
);
const VerifyRemovedParts = React.lazy(
  () => import("../components/master/busmaster/VerifyRemovedParts")
);
const DriverMaster = React.lazy(
  () => import("../components/master/drivermaster/DriverMaster")
);
const AddDriverMaster = React.lazy(
  () => import("../components/master/drivermaster/AddDriver")
);
const EditDriverMaster = React.lazy(
  () => import("../components/master/drivermaster/EditDriver")
);

const RouteMaster = React.lazy(
  () => import("../components/master/routemaster/RouteMaster")
);
const AddRouteMaster = React.lazy(
  () => import("../components/master/routemaster/AddRoute")
);
const EditRouteMaster = React.lazy(
  () => import("../components/master/routemaster/EditRoute")
);

const DepotMaster = React.lazy(
  () => import("../components/master/depotmaster/DepotMaster")
);
const AddDepotMaster = React.lazy(
  () => import("../components/master/depotmaster/AddDepot")
);
const EditDepotMaster = React.lazy(
  () => import("../components/master/depotmaster/EditDepot")
);

const DivisionMaster = React.lazy(
  () => import("../components/master/divisionmaster/DivisionMaster")
);
const AddDivisionMaster = React.lazy(
  () => import("../components/master/divisionmaster/AddDivision")
);
const EditDivisionMaster = React.lazy(
  () => import("../components/master/divisionmaster/EditDivision")
);

const UserMaster = React.lazy(
  () => import("../components/master/usermaster/UserMaster")
);
const AddUserMaster = React.lazy(
  () => import("../components/master/usermaster/AddUser")
);
const EditUserMaster = React.lazy(
  () => import("../components/master/usermaster/EditUserMaster")
);

const RoleMaster = React.lazy(
  () => import("../components/master/rolemaster/RoleMaster")
);
const AddRoleMaster = React.lazy(
  () => import("../components/master/rolemaster/AddRole")
);
const EditRoleMaster = React.lazy(
  () => import("../components/master/rolemaster/EditRole")
);

const PartsMaster = React.lazy(
  () => import("../components/master/partmaster/PartMaster")
);
const AddPartsMaster = React.lazy(
  () => import("../components/master/partmaster/AddParts")
);
const EditPartsMaster = React.lazy(
  () => import("../components/master/partmaster/EditPart")
);

const ManufacturerMaster = React.lazy(
  () => import("../components/master/ManufacturerMaster/ManufacturerMaster")
);
const AddManufacturerMaster = React.lazy(
  () => import("../components/master/ManufacturerMaster/AddManufacturer")
);
const EditManufacturerMaster = React.lazy(
  () => import("../components/master/ManufacturerMaster/EditManufacturer")
);

const MaintenanceMaster = React.lazy(
  () =>
    import("../components/master/maintenanceObservationMaster/GetObservation")
);
const AddMaintenanceMaster = React.lazy(
  () =>
    import("../components/master/maintenanceObservationMaster/AddObservation")
);
const EditMaintenanceMaster = React.lazy(
  () =>
    import(
      "../components/master/maintenanceObservationMaster/UpdateObservation"
    )
);

const PreCheck = React.lazy(
  () => import("../components/forms/PreCheckForm/PreCheck")
);

const WIP = React.lazy(() => import("../components/wip/Wip"));
const WipCompleted = React.lazy(() => import("../components/wip/WipCompleted"));
const WipPartsAwaitedList = React.lazy(
  () => import("../components/wip/WipPartsAwaitedList")
);
const WipPartsAwaited = React.lazy(
  () => import("../components/wip/WipPartsAwaited")
);

const HandOver = React.lazy(() => import("../components/handover/HandOver"));
const ViewHandOver = React.lazy(
  () => import("../components/handover/ViewHandOver")
);
const PartsAdded = React.lazy(
  () => import("../components/handover/PartsAdded")
);
const PartsRemoved = React.lazy(
  () => import("../components/handover/PartsRemoved")
);

const UpdateInvoice = React.lazy(
  () => import("../components/invoice/GenerateInvoice")
);

const UpdatePayment = React.lazy(
  () => import("../components/payment/UnpaidPayment")
);

const PaymentReceived = React.lazy(
  () => import("../components/payment/PaymentReceived")
);

const Maintenance = React.lazy(
  () => import("../components/maintenance/Maintenance")
);
const DailyMaintenance = React.lazy(
  () => import("../components/maintenance/DailyMaintenance")
);
const WeeklyMaintenance = React.lazy(
  () => import("../components/maintenance/WeeklyMaintenance")
);
const MonthlyMaintenance = React.lazy(
  () => import("../components/maintenance/MonthlyMaintenance")
);
// const BreakdownMaintenance = React.lazy(
//   () => import("../components/maintenance/AddBreakdownMaintenance")
// );
// const AccidentMaintenance = React.lazy(
//   () => import("../components/maintenance/AddAccidentMaintenance")
// );
const HistoryMaintenance = React.lazy(
  () => import("../components/maintenance/HistoryMaintenance")
);

const MaintenanceLogs = React.lazy(
  () => import("../components/maintenance/MaintenanceLogs")
);

const ViewMaintenanceLogs = React.lazy(
  () => import("../components/maintenance/ViewMaintenanceLogs")
);

const BulkUploadBus = React.lazy(
  () => import("../components/bulk-upload/BulkUploadBus")
);

const Error404 = React.lazy(() => import("../components/erorr/NotFound"));
// const Error403 = React.lazy(() => import("../components/erorr/UnAuthorized"));

const Operations = React.lazy(
  () => import("../components/operations/Operations")
);

const AddOperation = React.lazy(
  () => import("../components/operations/AddOperation")
);

const EditOperation = React.lazy(
  () => import("../components/operations/EditOperation")
);

const Reports = React.lazy(() => import("../components/reports/Reports"));

const AddReports = React.lazy(() => import("../components/reports/AddReports"));

const ViewReports = React.lazy(
  () => import("../components/reports/ViewDailyReports")
);
const PrintDailyReports = React.lazy(
  () => import("../components/reports/PrintReports")
);

const ChangePassword = React.lazy(
  () => import("../components/auth/ChangePassword/ChangePassword")
);

const DashboardPage = withLoading(Dashboard);
const ClientDashboardPage = withLoading(ClientDashboard);

const BusMasterPage = withLoading(BusMaster);
const AddBusPage = withLoading(AddBus);
const EditBusPage = withLoading(EditBus);
const ChangeDepotPage = withLoading(ChangeDepot);
const ViewBusPage = withLoading(ViewBus);
const IssueMasterPage = withLoading(RaiseIssue);
const VerifyRemovedPartsPage = withLoading(VerifyRemovedParts);

const DriverMasterPage = withLoading(DriverMaster);
const AddDriverMasterPage = withLoading(AddDriverMaster);
const EditDriverMasterPage = withLoading(EditDriverMaster);

const RouteMasterPage = withLoading(RouteMaster);
const AddRouterMasterPage = withLoading(AddRouteMaster);
const EditRouteMasterPage = withLoading(EditRouteMaster);

const DepotMasterPage = withLoading(DepotMaster);
const AddDepotMasterPage = withLoading(AddDepotMaster);
const EditDepotMasterPage = withLoading(EditDepotMaster);

const DivisionMasterPage = withLoading(DivisionMaster);
const AddDivisionMasterPage = withLoading(AddDivisionMaster);
const EditDivisionMasterPage = withLoading(EditDivisionMaster);

const UserMasterPage = withLoading(UserMaster);
const AddUserMasterPage = withLoading(AddUserMaster);
const EditUserMasterPage = withLoading(EditUserMaster);

const RoleMasterPage = withLoading(RoleMaster);
const AddRoleMasterPage = withLoading(AddRoleMaster);
const EditRoleMasterPage = withLoading(EditRoleMaster);

const PartsMasterPage = withLoading(PartsMaster);
const AddPartsMasterPage = withLoading(AddPartsMaster);
const EditPartsMasterPage = withLoading(EditPartsMaster);

const ManufacturerMasterPage = withLoading(ManufacturerMaster);
const AddManufacturerMasterPage = withLoading(AddManufacturerMaster);
const EditManufacturerMasterPage = withLoading(EditManufacturerMaster);

const MaintenanceObservationPage = withLoading(MaintenanceMaster);
const AddMaintenanceObservationPage = withLoading(AddMaintenanceMaster);
const EditMaintenanceObservationPage = withLoading(EditMaintenanceMaster);

const PreCheckPage = withLoading(PreCheck);

const WIPPage = withLoading(WIP);
const WipCompletedPage = withLoading(WipCompleted);
const WipPartsAwaitedPage = withLoading(WipPartsAwaited);
const WipPartsAwaitedListPage = withLoading(WipPartsAwaitedList);

const HandOverPage = withLoading(HandOver);
const PartsAddedPage = withLoading(PartsAdded);
const PartsRemovedPage = withLoading(PartsRemoved);
const ViewHandOverPage = withLoading(ViewHandOver);

const UpdateInvoicePage = withLoading(UpdateInvoice);
const UpdatePaymentPage = withLoading(UpdatePayment);
const PaymentReceivedPage = withLoading(PaymentReceived);

const MaintenancePage = withLoading(Maintenance);
const DailyMaintenancePage = withLoading(DailyMaintenance);
const WeeklyMaintenancePage = withLoading(WeeklyMaintenance);
const MonthlyMaintenancePage = withLoading(MonthlyMaintenance);
// const BreakdownMaintenancePage = withLoading(BreakdownMaintenance);
// const AccidentMaintenancePage = withLoading(AccidentMaintenance);
const HistoryMaintenancePage = withLoading(HistoryMaintenance);
const MaintenanceLogsPage = withLoading(MaintenanceLogs);
const ViewMaintenanceLogsPage = withLoading(ViewMaintenanceLogs);

const BulkUploadBusPage = withLoading(BulkUploadBus);

const OperationPage = withLoading(Operations);
const AddOperationPage = withLoading(AddOperation);
const EditOperationPage = withLoading(EditOperation);

const ReportsPage = withLoading(Reports);
const AddReportsPage = withLoading(AddReports);
const ViewReportsPage = withLoading(ViewReports);
const PrintReportsPage = withLoading(PrintDailyReports);

const ChangePasswordPage = withLoading(ChangePassword);

const Error404Page = withLoading(Error404);
// const Error403Page = withLoading(Error403);

const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const user = useAppSelector((state) => state.user.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={protectedLayout}>
          <Route
            index
            element={
              user?.role === Roles.CLIENT ? (
                <Navigate to="/client-dashboard" />
              ) : user?.role === Roles.VENDOR ||
                user?.role === Roles.SUPER_ADMIN ||
                user?.role === Roles.MSRTC ||
                user?.role === Roles.DIVISION ? (
                <RoleBasedAuth
                  allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                  permissions={[SidebarPermission.VENDOR_DASHBAORD]}
                >
                  <DashboardPage />
                </RoleBasedAuth>
              ) : (
                <Navigate to="/maintenance" />
              )
            }
          />

          <Route
            path="client-dashboard"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT]}
                permissions={[SidebarPermission.CLIENT_DASHBAORD]}
              >
                <ClientDashboardPage />
              </RoleBasedAuth>
            }
          />
          {/* Bus Master Start */}
          <Route
            path="bus-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR, Roles.CLIENT]}
                permissions={[
                  SidebarPermission.BUS_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_BUS,
                ]}
              >
                <BusMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-bus"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT]}
                permissions={[MasterPermission.ADD_BUS]}
              >
                <AddBusPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="view-bus-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR, Roles.CLIENT]}
                permissions={[MasterPermission.GET_BUS]}
              >
                <ViewBusPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-bus"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT]}
                permissions={[MasterPermission.UPDATE_BUS]}
              >
                <EditBusPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="change-depot" // by mayuresh
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT]}
                permissions={[MasterPermission.UPDATE_BUS]} // using UPDATE_BUS permission for change-depot
              >
                <ChangeDepotPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="issue-master" // by mayuresh
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT, Roles.VENDOR]}
                permissions={[SidebarPermission.ISSUE_MASTER]}
              >
                <IssueMasterPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="add-issue" // by mayuresh
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT]}
                permissions={[MasterPermission.ADD_ISSUE]}
              >
                <AddIssue />
              </RoleBasedAuth>
            }
          />
          <Route
            path="edit-issue" // by mayuresh
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_ISSUE]}
              >
                <EditIssue />
              </RoleBasedAuth>
            }
          />

          <Route
            path="verify-removed-parts" // by mayuresh
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT, Roles.VENDOR]}
                permissions={[MasterPermission.VERIFY_REMOVED_PARTS]}
              >
                <VerifyRemovedPartsPage />
              </RoleBasedAuth>
            }
          />
          {/* Bus Master End */}

          {/* Driver Master Start */}
          <Route
            path="driver-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.DRIVER_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_DRIVERS,
                ]}
              >
                <DriverMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-driver"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_DRIVERS]}
              >
                <AddDriverMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-driver"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_DRIVERS]}
              >
                <EditDriverMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Driver Master End */}

          {/* Route Master Start */}
          <Route
            path="route-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.ROUTE_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_ROUTE,
                ]}
              >
                <RouteMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-route"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_ROUTE]}
              >
                <AddRouterMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-route"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_ROUTE]}
              >
                <EditRouteMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Route Master End */}

          {/* User Master Start */}
          <Route
            path="user-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.USER_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_USER,
                ]}
              >
                <UserMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-user"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_USER]}
              >
                <AddUserMasterPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="edit-user"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_USER]}
              >
                <EditUserMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* User Master End */}

          {/* Role Master Start */}
          <Route
            path="role-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.ROLE_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_ROLE,
                ]}
              >
                <RoleMasterPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="add-role"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_ROLE]}
              >
                <AddRoleMasterPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="edit-role"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_ROLE]}
              >
                <EditRoleMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Role Master End */}

          {/* Divsion Master Start */}
          <Route
            path="division-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.DIVISION_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_DIVISIONS,
                ]}
              >
                <DivisionMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-division"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_DIVISIONS]}
              >
                <AddDivisionMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-division"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_DIVISIONS]}
              >
                <EditDivisionMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Division Master End */}

          {/* Depot Master Start */}
          <Route
            path="depot-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.DEPOT_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_DEPOTS,
                ]}
              >
                <DepotMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-depot"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_DEPOTS]}
              >
                <AddDepotMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-depot"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_DEPOTS]}
              >
                <EditDepotMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Depot Master End */}

          {/* Parts Master Start */}
          <Route
            path="parts-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.PARTS_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_PARTS,
                ]}
              >
                <PartsMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-part"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_PARTS]}
              >
                <AddPartsMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-part"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_PARTS]}
              >
                <EditPartsMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Parts Master End */}

          {/* Manufacturer Master Start */}
          <Route
            path="manufacturer-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.MANUFACTURER_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_ALL_PARTS_MANUFACTURER,
                ]}
              >
                <ManufacturerMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-manufacturer"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_PARTS_MANUFACTURER]}
              >
                <AddManufacturerMasterPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-manufacturer"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_PARTS_MANUFACTURER]}
              >
                <EditManufacturerMasterPage />
              </RoleBasedAuth>
            }
          />
          {/* Manufacturer Master End */}

          {/* Maintenance Observation Master Start */}
          <Route
            path="maintenance-observation-master"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  SidebarPermission.MAINTENANCE_OBSERVATION_MASTER,
                  SidebarPermission.MASTERS,
                  MasterPermission.GET_MAINTENANCE_FIELD,
                ]}
              >
                <MaintenanceObservationPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-maintenance-observation"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.ADD_MAINTENANCE_FIELD]}
              >
                <AddMaintenanceObservationPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-maintenance-observation"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MasterPermission.UPDATE_MAINTENANCE_FIELD]}
              >
                <EditMaintenanceObservationPage />
              </RoleBasedAuth>
            }
          />
          {/* Maintenance Observation Master End */}

          <Route
            path="pre-check"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.PRE_CHECK]}
              >
                <PreCheckPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="wip-parts-awaited"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT, Roles.VENDOR]}
                permissions={[SidebarPermission.WIP_PARTS_AWAITED]}
              >
                <WipPartsAwaitedListPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="parts-awaited"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.CLIENT, Roles.VENDOR]}
                permissions={[SidebarPermission.WIP_PARTS_AWAITED]}
              >
                <WipPartsAwaitedPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="wip"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.WIP]}
              >
                <WIPPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="completed"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.WIP_COMPLETED]}
              >
                <WipCompletedPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="handed-over"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.HAND_OVER]}
              >
                <HandOverPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="view-hand-over"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.HAND_OVER]}
              >
                <ViewHandOverPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="parts-added"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.HAND_OVER]}
              >
                <PartsAddedPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="parts-removed"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.HAND_OVER]}
              >
                <PartsRemovedPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="generate-invoice"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.UPDATE_INVOICE]}
              >
                <UpdateInvoicePage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="unpaid-status"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.UPDATE_PAYMENT]}
              >
                <UpdatePaymentPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="payment-received"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[SidebarPermission.UPDATE_PAYMENT]}
              >
                <PaymentReceivedPage />
              </RoleBasedAuth>
            }
          />

          {/* Maintenance Start  */}
          <Route
            path="maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.GET_MAINTENANCE_DASHBOARD]}
              >
                <MaintenancePage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="daily-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.DAILY_MAINTENANCE]}
              >
                <DailyMaintenancePage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="weekly-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.WEEKLY_MAINTENANCE]}
              >
                <WeeklyMaintenancePage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="monthly-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.MONTHLY_MAINTENANCE]}
              >
                <MonthlyMaintenancePage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-breakdown-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.ADD_BREAKDOWN_MAINTENANCE]}
              >
                <AddBreakdownMaintenance />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-accident-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.ADD_ACCIDENT_MAINTENANCE]}
              >
                <AddAccidentMaintenance />
              </RoleBasedAuth>
            }
          />

          <Route
            path="accident-breakdown"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  MaintenancePermission.GET_BREAKDOWN_ACCIDENT_BUS_DATA,
                ]}
              >
                <AccidentBreakdownList />
              </RoleBasedAuth>
            }
          />

          <Route
            path="view-accident-breakdown"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  MaintenancePermission.GET_BREAKDOWN_ACCIDENT_DATA,
                ]}
              >
                <ViewAccidentBreakdown />
              </RoleBasedAuth>
            }
          />

          <Route
            path="history-maintenance"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.GET_MAINTENANCE_HISTORY]}
              >
                <HistoryMaintenancePage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="maintenance-logs"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[MaintenancePermission.GET_MAINTENANCE_LOG]}
              >
                <MaintenanceLogsPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="view-maintenance-logs"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.SUPER_ADMIN, Roles.VENDOR]}
                permissions={[
                  MaintenancePermission.CLIENT_VIEW_MAINTENANCE_LOG,
                ]}
              >
                <ViewMaintenanceLogsPage />
              </RoleBasedAuth>
            }
          />
          {/* Maintenance End  */}

          <Route
            path="bulk-upload"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.CLIENT]}
                permissions={[MasterPermission.ADD_BUS]}
              >
                <BulkUploadBusPage />
              </RoleBasedAuth>
            }
          />

          {/* Operation Start Here */}
          <Route
            path="operation"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[OperationPermission.GET_OPERATION_WITH_START_KM]}
              >
                <OperationPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="add-operation"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[OperationPermission.ADD_OPERATION]}
              >
                <AddOperationPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="edit-operation"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[OperationPermission.UPDATE_OPERATION]}
              >
                <EditOperationPage />
              </RoleBasedAuth>
            }
          />

          <Route
            path="change-password"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={["change_password"]}
              >
                <ChangePasswordPage />
              </RoleBasedAuth>
            }
          />
          {/* Operation End Here */}

          {/* Reports Start Here */}
          <Route
            path="reports"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[
                  SidebarPermission.SHOW_REPORTS,
                  ReportsPermission.GET_REPORTS,
                ]}
              >
                <ReportsPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="add-reports"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[ReportsPermission.ADD_REPORTS]}
              >
                <AddReportsPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="view-reports"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR]}
                permissions={[ReportsPermission.GET_REPORTS]}
              >
                <ViewReportsPage />
              </RoleBasedAuth>
            }
          />
          <Route
            path="print-reports"
            element={
              <RoleBasedAuth
                allowRoles={[Roles.VENDOR, Roles.CLIENT, Roles.SUPER_ADMIN]}
                permissions={[ReportsPermission.GET_REPORTS]}
              >
                <PrintReportsPage />
              </RoleBasedAuth>
            }
          />
          {/* Reports End Here */}
        </Route>

        <Route path="login" element={<LoginForm />} />
        <Route path="forgot-password" element={<ForgotPassword />} />

        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/under-maintenace" element={<SiteMaintenance />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
