import { useEffect, useState } from "react";
import depotService, { IDepotList } from "../services/DepotService";

export const useDepotList = () => {
  const [depots, setDepots] = useState<IDepotList[]>([]);

  const getDepots = async () => {
    const { data } = await depotService.getDepotList();

    if (data) setDepots(data);
  };

  useEffect(() => {
    getDepots();
  }, []);

  return { depots };
};
