import { Field, Form, Formik, ErrorMessage, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AccidentMaintenanceWithDivision, AccidentMaintenanceWithoutDivision } from "../../schemas/maintenance.schema";
import { useNavigate } from "react-router-dom";
import { useDepotList } from "../../hooks/useDepotList";
import maintenanceService from "../../services/MaintenanceService";
import { handleAxiosError } from "../../utills/helper";
import { notificationController } from "../../utills/toastNotification";
import { IAccidentMaintenance } from "../../interfaces/maintenance.interface";
import {
  CngFormData,
  IManufacturer,
} from "../../interfaces/handover.interface";
import handOverService from "../../services/HandoverService";
import depotService from "../../services/DepotService";
import _ from "lodash";
import moment from "moment";
import { useAppSelector } from "../../hooks/reduxHooks";
import { Roles } from "../../interfaces/role.interface";
import divisionService from "../../services/DivisionService";

const AddAccidentMaintenance: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const cssHandling = user?.role !== Roles.DIVISION

  const [cngFormData, setCngFormData] = useState<CngFormData[]>([]);
  const [busList, setBusList] = useState<
    { registerationNumber: string; _id: string }[]
  >([]);
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string;
  } | null>(null);
  const [rawMap, setRawMap] = useState<{ [key: string]: IManufacturer }>({});
  const [selectedDepot, setSelectedDepot] = useState(null);
  const [showCngParts, setShwoCngPart] = useState<boolean>(false);
  const [showBusList, setShowBusList] = useState<boolean>(false);
  const [divisionList, setDivisionList] = useState<any>()
  const [depotList, setDepotList] = useState<any>(null)
  const { depots } = useDepotList();

  const handleBack = () => {
    navigate("/accident-breakdown");
  };

  const getBusByDepotLink = async (busId: string) => {
    try {
      if (busId) {
        const { data } = await depotService.getDepotDependentBusList(busId);
        if (data.length > 0) {
          setShowBusList(true);
          setBusList(data);
        } else {
          setBusList([]);
        }
      }
    } catch (error) {
      setShowBusList(false);
      handleAxiosError(error);
    }
  };

  const handleAddAccidentMaintenance = async (data: IAccidentMaintenance) => {
    try {
      await maintenanceService.addAccidentMaintenance(data);
      notificationController.success("Accident Maintenance Added Successfully");
      return true;
    } catch (err) {
      handleAxiosError(err);
      return false;
    }
  };

  const getDivisionData = async () => {
    try {
      const { data } = await divisionService.getDivisionList();
      setDivisionList(data);
    } catch (err) {
      handleAxiosError(err);
    }
  };

  const getUserDepotLists = async (value: any) => {
    if (value !== null) {
      const { data } = await depotService.getUserDepotList(value)
      setDepotList(data)
    }
  }

  const getHandOverFormById = async (busId: string) => {
    try {
      if (busId) {
        const { data } = await handOverService.getHandOverFormDetails(busId);

        let obj: { [key: string]: any } = {};
        let init: { [key: string]: string } = {};

        [...data.cngFormData].forEach((element) => {
          obj[element.name] = element;
          if (element.manufacturer.length === 1) {
            init[element.name] = element.manufacturer[0]._id;
          } else {
            init[element.name] = "";
          }
        });

        setRawMap(obj);
        setCngFormData(
          Object.entries(obj).map(([key, value]) => {
            return {
              ...value,
              name: key,
            };
          })
        );
        setShwoCngPart(true);
        setInitialValues(init);
        const nonEmptyEntries = Object.entries(init).filter(
          ([key, value]) => value !== ""
        );
        return nonEmptyEntries;
      }
    } catch (error) {
      setShwoCngPart(false);
      handleAxiosError(error);
    }
  };

  useEffect(() => {
    getDivisionData()
  }, [])

  useEffect(() => {
    if (depots.length === 1) {
      getBusByDepotLink(depots[0]._id);
      setSelectedDepot(depots[0]._id as any);
    }
  }, [depots, initialValues]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <h4 className="fw-bold py-3 mb-4">
        <span className="text-muted fw-light">Accident Maintenance </span>
      </h4>
      <div className="row">
        <div className="card">
          {depots && depots.length > 0 && (
            <Formik
              initialValues={{
                ...initialValues,
                ConvertedEngineNo: "",
                BatteryRatingOne: "",
                BatteryRatingTwo: "",
                SpareTyreCage: "Right Side Rear",
                cngInKG: "",
                depot: depots.length === 1 ? depots[0]._id : "",
                bus: "",
                repair: "",
                dateTime: moment().format("YYYY-MM-DD"),
                remark: "",
                division: null,
              }}
              validationSchema={cssHandling ? AccidentMaintenanceWithDivision : AccidentMaintenanceWithoutDivision}
              onSubmit={async (values, { resetForm }) => {
                let CNGParts: { partId: string; manufacturerId: string }[] = [];

                for (const key in values) {
                  let filterCngValue = _.find(cngFormData, {
                    name: key,
                    type: "CNG",
                  });

                  if (
                    filterCngValue &&
                    filterCngValue?._id &&
                    (values as any)[key]
                  ) {
                    CNGParts.push({
                      partId: filterCngValue?._id as string,
                      manufacturerId: (values as any)[key],
                    });
                  }
                }

                const result = await handleAddAccidentMaintenance({
                  depot: values.depot,
                  bus: values.bus,
                  repair: values.repair,
                  dateTime: values.dateTime,
                  remark: values.remark,
                  ConvertedEngineNo: values.ConvertedEngineNo,
                  BatteryRatingOne: values.BatteryRatingOne,
                  BatteryRatingTwo: values.BatteryRatingTwo,
                  SpareTyreCage: values.SpareTyreCage,
                  cngInKG: values.cngInKG,
                  CNGParts: CNGParts.length > 0 ? CNGParts : [],
                });
                if (result) {
                  resetForm();
                  setTimeout(() => {
                    navigate("/accident-breakdown");
                  }, 2000);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="table-responsive text-nowrap">
                      <table className="table">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "rgba(105, 108, 255, 0.16)",
                            }}
                          >
                            <th className="col-sm-6">Particulars</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cssHandling ?
                            <tr className="table-active">
                              <td>Divison</td>
                              <td>
                                <div className="col-sm-9">
                                  <Field
                                    className="form-select"
                                    component="select"
                                    id="division"
                                    name="division"
                                    onChange={handleChange}
                                    onClick={() => getUserDepotLists(values.division)}
                                  >
                                    <option value="">Select Division</option>
                                    {divisionList &&
                                      divisionList.length > 0 &&
                                      divisionList.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </Field>
                                  {errors.division && touched.division ? (
                                    <div
                                      style={{ color: "red", marginTop: "2px" }}
                                    >
                                      {errors.division}
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                            : null}
                          <tr>
                            <td>Select Depot</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="depot"
                                  component="select"
                                  aria-label="Default select example"
                                  name="depot"
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    if (e.target.value) {
                                      getBusByDepotLink(e.target.value);
                                      setSelectedDepot(e.target.value);
                                    } else {
                                      setSelectedDepot(null);
                                    }
                                  }}
                                >
                                  <option value="">Select Depot</option>
                                  {
                                    cssHandling ?
                                      depotList &&
                                      depotList.length > 0 &&
                                      depotList.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))
                                      :
                                      depots &&
                                      depots.length > 0 &&
                                      depots.map((item: any) => (
                                        <option value={item._id} key={item._id}>
                                          {item.name}
                                        </option>
                                      ))
                                  }
                                </Field>
                                {errors.depot && touched.depot ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.depot}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr className="table-active">
                            <td>Select Bus</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="exampleFormControlSelect1"
                                  aria-label="Default select example"
                                  name="bus"
                                  component="select"
                                  onChange={async (e: any) => {
                                    handleChange(e);
                                    const result = await getHandOverFormById(
                                      e.target.value
                                    );
                                    if (result) {
                                      result.forEach(([key, value]) => {
                                        setFieldValue(key, value);
                                      });
                                    }
                                  }}
                                >
                                  <option value="">Select Bus No</option>
                                  {selectedDepot !== null &&
                                    showBusList &&
                                    busList &&
                                    busList.length > 0 &&
                                    busList.map((item) => (
                                      <option value={item._id} key={item._id}>
                                        {item.registerationNumber}
                                      </option>
                                    ))}
                                </Field>
                                {errors.bus && touched.bus ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.bus}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Repair</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-select"
                                  id="repair"
                                  component="select"
                                  aria-label="Default select example"
                                  name="repair"
                                  onChange={handleChange}
                                >
                                  <option value="">Select</option>
                                  {[
                                    { _id: 1, name: "COMPLETE" },
                                    { _id: 2, name: "PARTIAL" },
                                  ].map((item) => (
                                    <option value={item.name} key={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Field>
                                {errors.repair && touched.repair ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.repair}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr className="table-active">
                            <td>Date</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  className="form-control date-control"
                                  type="date"
                                  id="dateTime"
                                  name="dateTime"
                                  onChange={handleChange}
                                  value={values.dateTime}
                                  max={moment().format("YYYY-MM-DD")}
                                />
                                {errors.dateTime && touched.dateTime ? (
                                  <div
                                    style={{ color: "red", marginTop: "2px" }}
                                  >
                                    {errors.dateTime}
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Remark</td>
                            <td>
                              <div className="col-sm-9">
                                <Field
                                  id="remark"
                                  name="remark"
                                  className="form-control"
                                  placeholder="Remark"
                                  onChange={handleChange}
                                  component="textarea"
                                  maxLength="300"
                                  rows="2"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {selectedDepot !== null && showCngParts && (
                      <>
                        <span className="text-muted fw-light">CNG Parts</span>

                        <div className="table-responsive text-nowrap">
                          <table className="table">
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "rgba(105, 108, 255, 0.16)",
                                }}
                              >
                                <th className="col-sm-6">Particulars</th>
                                <th>Company</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cngFormData &&
                                cngFormData.length > 0 &&
                                cngFormData
                                  .filter((item) => item.type === "CNG")
                                  .map((item, index) => (
                                    <tr
                                      className={
                                        index % 2 === 0 ? "table-active" : ""
                                      }
                                      key={item._id}
                                    >
                                      {/* <td>1</td> */}
                                      <td>
                                        <label
                                          className="col-sm-2 col-form-label"
                                          htmlFor="basic-default-name"
                                          key={item._id}
                                        >
                                          {item.name}
                                        </label>
                                      </td>
                                      <td>
                                        <div className="col-sm-9">
                                          <Field
                                            className="form-select"
                                            component="select"
                                            id={item.name}
                                            name={item.name}
                                          >
                                            <option value="">Select</option>
                                            {item.manufacturer &&
                                              item.manufacturer.length > 0 &&
                                              item.manufacturer.map((item) => (
                                                <option
                                                  value={item._id}
                                                  key={item._id}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                          </Field>
                                          <ErrorMessage
                                            name={item.name}
                                            render={(msg) => (
                                              <div className="errorMessage">
                                                {msg}
                                              </div>
                                            )}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}

                              <tr>
                                <td>
                                  <label
                                    className="col-sm-2 col-form-label"
                                    htmlFor="basic-default-name"
                                  >
                                    Engine No. (CNG Type)
                                  </label>
                                </td>
                                <td>
                                  <div className="col-sm-9">
                                    <Field
                                      name="ConvertedEngineNo"
                                      className="form-control"
                                      maxLength="20"
                                    />
                                    <ErrorMessage
                                      name={"ConvertedEngineNo"}
                                      render={(msg) => (
                                        <div className="errorMessage">
                                          {msg}
                                        </div>
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr className={"table-active"}>
                                <td>
                                  <label
                                    className="col-sm-2 col-form-label"
                                    htmlFor="basic-default-name"
                                  >
                                    Battery Rating (V/Ah) with Battery No.
                                  </label>
                                </td>
                                <td>
                                  <div className="col-sm-9">
                                    <div className="row">
                                      <div className="col-sm-6">
                                        <Field
                                          name="BatteryRatingOne"
                                          className="form-control"
                                          maxLength="20"
                                          placeholder="Battery Rating 1"
                                        />
                                        <ErrorMessage
                                          name={"BatteryRatingOne"}
                                          render={(msg) => (
                                            <div className="errorMessage">
                                              {msg}
                                            </div>
                                          )}
                                        />
                                      </div>
                                      <div className="col-sm-6">
                                        <Field
                                          name="BatteryRatingTwo"
                                          className="form-control"
                                          maxLength="20"
                                          placeholder="Battery Rating 2"
                                        />
                                        <ErrorMessage
                                          name={"BatteryRatingTwo"}
                                          render={(msg) => (
                                            <div className="errorMessage">
                                              {msg}
                                            </div>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <label
                                    className="col-sm-2 col-form-label"
                                    htmlFor="basic-default-name"
                                  >
                                    Spare Tyre Cage/ Bracket (Please mention the
                                    location)
                                  </label>
                                </td>
                                <td>
                                  <div className="col-sm-9">
                                    <Field
                                      name="SpareTyreCage"
                                      className="form-control"
                                      maxLength="20"
                                    />
                                    <ErrorMessage
                                      name={"SpareTyreCage"}
                                      render={(msg) => (
                                        <div className="errorMessage">
                                          {msg}
                                        </div>
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr className={"table-active"}>
                                <td>
                                  <label
                                    className="col-sm-2 col-form-label"
                                    htmlFor="basic-default-name"
                                  >
                                    CNG in Kg in CNG tank at the time of
                                    accepting the vehicle
                                  </label>
                                </td>
                                <td>
                                  <div className="col-sm-9">
                                    <Field
                                      name="cngInKG"
                                      className="form-control"
                                      maxLength="20"
                                    />
                                    <ErrorMessage
                                      name={"cngInKG"}
                                      render={(msg) => (
                                        <div className="errorMessage">
                                          {msg}
                                        </div>
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                    <div
                      className="demo-inline-spacing d-flex justify-content-center mt-5"
                      style={{ marginBottom: "10px", gap: "30px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>

                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddAccidentMaintenance;
