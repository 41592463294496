export const persistToken = (token: string): void => {
  localStorage.setItem("authToken", token);
};

export const readToken = (): string | null => {
  return localStorage.getItem("authToken");
};

export const deletePersistUser = (): void =>
  localStorage.removeItem("persist:root");
export const deleteToken = (): void => localStorage.removeItem("authToken");

export const persistClientBusLogToken = (log: string): void => {
  localStorage.setItem("busLog", log);
};

export const readClientBusLogToken = (): string | null => {
  return localStorage.getItem("busLog");
};

export const deleteClientBusLogToken = (): void => localStorage.removeItem("busLog");

export const persistClientStatusLogToken = (log: string): void => {
  localStorage.setItem("statusLog", log);
};

export const readClientStatusLogToken = (): string | null => {
  return localStorage.getItem("statusLog");
};

export const deleteClientStatusLogToken = (): void => localStorage.removeItem("statusLog");

export const persistReportDivisionToken = (log: string): void => {
  localStorage.setItem("reportDivision", log);
};

export const readReportDivisionToken = (): string | null => {
  return localStorage.getItem("reportDivision");
};

export const deleteReportDivisionToken = (): void => localStorage.removeItem("reportDivision");

export const persistReportDepotToken = (log: string): void => {
  localStorage.setItem("reportDepot", log);
};

export const readReportDepotToken = (): string | null => {
  return localStorage.getItem("reportDepot");
};

export const deleteReportDepotToken = (): void => localStorage.removeItem("reportDepot");
